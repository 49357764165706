import React, { useEffect, useState } from 'react'
import "./style.css"
import { RiAddCircleLine, RiCloseLine, RiEdit2Line, RiMailLine, RiCheckFill, RiCheckboxCircleFill } from "react-icons/ri";
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS, GET_USERSS } from '../graphql/query';
import { CREATE_USER, DELETE_USER, SEND_MAIL, UPDATE_USER } from '../graphql/mutation';

export default function User({user}) {
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [mail, setMail] = useState(false)
  const {data,loading} = useQuery(GET_USERS)
  const [selectId, setSelect] = useState("")

  const select = data?.getUsers?.filter((e)=>e?.id === selectId)[0]

  const [search, setSearch] = useState([])

  const handleSearch = (query) => {
    const filteredResults = data?.getUsers?.filter(item =>
      item?.name?.toLowerCase().includes(query.toLowerCase())
    );

    setSearch(filteredResults);
  };

  if(loading){
    return (
      <div className='Loading'>
        Loading...
      </div>
    )
  }
  return (
    <>
    <div className='Home'>
      <div className='HomeHead'>
        USERS
        <div className='FlexCon' />
      </div>
      <div className='UserDiv'>
        <div className='UserDivLeft'>
          <div className='UserSearchCon'>
            <input onChange={(e)=>handleSearch(e?.target?.value)} placeholder='Search user, email' />
            <div className='FlexCon' />
          </div>
          <div className='UserCon'>
            <div className='UserHead' onClick={()=>console.log(data?.getUsers)}>
              All Users
              <h4>{data?.getUsers?.length}</h4>
              <div className='FlexCon' />
              {user?.role === 'super admin'?
              <button onClick={()=>setOpen(true)}><RiAddCircleLine/> Create User</button>
              : null}
            </div>
            <div className='UserHead1'>
              <h2>Name</h2>
              <h2>Email</h2>
              <h2>Roles</h2>
              <h2>Lang</h2>
              <h2>Status</h2>
            </div>
            {search?.length>0?
            search?.map((item, index)=>{
              return <Users key={index} item={item} setSelect={setSelect}/>
            })
            :
            data?.getUsers?.slice()?.sort((a,b)=>{
              if ( a.name < b.name ){
                return -1;
              }
              if ( a.name > b.name ){
                return 1;
              }
              return 0;
            })?.map((item, index)=>{
              return(
                <Users key={index} item={item} setSelect={setSelect}/>
              )
            })}
          </div>
        </div>
        <div className='UserDivRight'>
          {select?
          <>
          <div className='UserDivRightProfile'>
            <img src='https://ittacademy.net.au/wp-content/uploads/2022/12/bio-placeholder-img.jpg'/>
            <div>
              <h2>{select?.name}</h2>
              <h3>Role: {select?.nations?.filter((e)=>e?.nd?.id === select?.id)?.length > 0? "ND":null} {select?.nations?.filter((e)=>e?.bc?.id === select?.id)?.length > 0? "BC":null} {select?.regions?.length>0?"RD":null} {select?.areas?.length>0?"AD":null}</h3>
            </div>
            <div className='FlexCon'/>
            {user?.role === 'super admin'?
            <>
            <div className='UserDivRightEdit' onClick={()=>setEdit(true)}>
              <RiEdit2Line/>
            </div>
            {/* <div className='UserDivRightEdit' style={{marginLeft:5}} onClick={()=>setMail(true)}>
              <RiMailLine/>
            </div> */}
            </> : null}
          </div>
          <div className='UserDivRightDiv'>
            <h4>Email:</h4>
            <h5>{select?.email}</h5>
          </div>
          {select?.nations?.filter((e)=>e?.nd?.id === select?.id)?.length > 0?
          <div className='UserDivRightDiv'>
            <h4>ND:</h4>
            <div>
            {select?.nations?.filter((e)=>e?.nd?.id === select?.id)?.map((itm, index)=>{
              return <h5 key={index}>{itm?.name}</h5>
            })}
            </div>
          </div>
          : null}
          {select?.nations?.filter((e)=>e?.bc?.id === select?.id)?.length >0?
          <div className='UserDivRightDiv'>
            <h4>BC:</h4>
            <div>
            {select?.nations?.filter((e)=>e?.bc?.id === select?.id)?.map((itm, index)=>{
              return <h5 key={index}>{itm?.name}</h5>
            })}
            </div>
          </div>
          : null}
          {select?.nations?.filter((e)=>e?.cw?.id === select?.id)?.length >0?
          <div className='UserDivRightDiv'>
            <h4>CW:</h4>
            <div>
            {select?.nations?.filter((e)=>e?.cw?.id === select?.id)?.map((itm, index)=>{
              return <h5 key={index}>{itm?.name}</h5>
            })}
            </div>
          </div>
          : null}
          {select?.regions?.length>0?
          <div className='UserDivRightDiv'>
            <h4>RD:</h4>
            <div>
            {select?.regions?.map((itm, index)=>{
              return <h5 style={{marginTop:0}} key={index}>{itm?.name}</h5>
            })}
            </div>
          </div>
          : null}
          {select?.areas?.length>0?
          <div className='UserDivRightDiv'>
            <h4>AD:</h4>
            <div>
            {select?.areas?.map((itm, index)=>{
              return <h5 key={index}>{itm?.name}</h5>
            })}
            </div>
          </div>
          : null}
          </>
          : null}
        </div>
      </div>
    </div>
    <div className={edit?'UserModal UserModalOpen':'UserModal'}>
      <EditUser setOpen={setEdit} open={select}/>
    </div>
    <div className={mail?'UserModal UserModalOpen':'UserModal'}>
      <SendMail setOpen={setMail} open={mail} select={select}/>
    </div>
    <div className={open?'UserModal UserModalOpen':'UserModal'}>
      <CreateUser setOpen={setOpen} open={open}/>
    </div>
    </>
  )
}

function CreateUser({setOpen, open}){
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [mail, setMail] = useState('Yes')
  const [admin, setAdmin] = useState("")

  const [onCreate, {loading}] = useMutation(CREATE_USER,{
    refetchQueries:[
      {query:GET_USERSS}
    ]
  })

  return(
    <div className='UserModalCon'>
    <div className='UserModalHead'>
      Add User
    </div>
    <RiCloseLine className='UserModalClose' size={18} onClick={()=>setOpen(false)}/>
      <div className='UserModalCon1'>
        <div>
          <h2>User Name</h2>
          <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter User Name'/>
        </div>
        <div className='UserModalInput'>
          <h2>Email Address</h2>
          <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Enter Email Address'/>
        </div>
      </div>
      <div className='UserModalCon1'>
        <div>
        <h2>Password</h2>
          <input value={password} onChange={(e)=>setPassword(e.target.value)} placeholder='Create Password'/>
        </div>
        <div className='UserModalInput'>
          <h2>Admin role</h2>
          <select value={admin} onChange={(e)=>setAdmin(e.target.value)}>
            <option value={""}>Not an admin</option>
            <option value={"GV"}>Global view</option>
            <option value={"GE"}>Global edit</option>
          </select>
        </div>
      </div>
      <div className='UserModalCon4'>
        <div className='FlexCon'/>
        <input checked={mail === 'Yes'} onChange={()=>mail === 'Yes'? setMail('No'):setMail('Yes')} type='checkbox'/>
        <p onClick={()=>mail === 'Yes'? setMail('No'):setMail('Yes')}>Send Email</p>
        <button onClick={()=>{
          onCreate({
            variables:{
              "user": {
                "email": email,
                "name": name,
                "password": password,
                "mail":mail,
                "admin":admin
              }
            }
          }).then(()=>{
            setOpen(false)
            setEmail("")
            setMail("")
            setPassword("")
            setName("")
          }).catch((err)=>{
            alert(err?.message)
          })
        }}>{loading? "Loading" : "Create User"}</button>
      </div>
    </div>
  )
}

function SendMail({setOpen, open, select}){
  const [type, setType] = useState("")
  const [send,{loading}] = useMutation(SEND_MAIL)
  
  const OnSend = () =>{
    send({
      variables:{
        "sendMailId": select?.email,
        "type": type
      }
    })
  }

  return(
    <div className='UserModalCon'>
      <div className='UserModalHead' onClick={()=>console.log(open)}>
        Send Mail
      </div>
      <RiCloseLine className='UserModalClose' size={18} onClick={()=>{
        setOpen(false)
      }}/>
      <div className='UserModalCon1'>
        <div>
          <h2>Email</h2>
          <input defaultValue={select?.email} disabled/>
        </div>
        <div className='UserModalInput'>
          <h2>Template</h2>
          <select value={type} onChange={(e)=>setType(e.target.value)}>
            <option hidden>Select Template</option>
            <option value={'Open'}>Chartering is Open</option>
          </select>
        </div>
      </div>
      <div className='UserModalCon4'>
        <div className='FlexCon'/>
        <button onClick={()=>loading? null : OnSend()}>{loading? "Sending" : "Send Mail"}</button>
      </div>
    </div>
  )
}

function EditUser({setOpen, open}){
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [mail, setMail] = useState('No')
  const [del, setDel] = useState(false)
  const [status, setStatus] = useState("")
  const [language, setLanguage] = useState("")
  
  useEffect(()=>{
    setEmail(open?.email)
    setName(open?.name)
    setLanguage(open?.language)
    setPassword("")
    setMail("No")
    setStatus(open?.status || '')
  },[open])

  const [onCreate, {loading}] = useMutation(UPDATE_USER,{
    refetchQueries:[
      {query:GET_USERSS}
    ]
  })

  const [load, setLoad] = useState(false)

  const [onDelete] = useMutation(DELETE_USER,{
    refetchQueries:[
      {query:GET_USERSS}
    ]
  })

  return(
    <div className='UserModalCon'>
    <div className='UserModalHead' onClick={()=>console.log(open)}>
      Edit User
    </div>
    <RiCloseLine className='UserModalClose' size={18} onClick={()=>{
      setOpen(false)
      setDel(false)
    }}/>
      <div className='UserModalCon1'>
        <div>
          <h2>User Name</h2>
          <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter User Name'/>
        </div>
        <div className='UserModalInput'>
          <h2>Email Address</h2>
          <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Enter Email Address'/>
        </div>
      </div>
      <div className='UserModalCon1'>
        <div>
          <h2>Password</h2>
          <input value={password} onChange={(e)=>setPassword(e.target.value)} placeholder='Create New Password'/>
        </div>
        <div className='UserModalInput'>
          <h2>Status</h2>
          <select value={status} onChange={(e)=>setStatus(e.target.value)}>
            <option value={''}>Active</option>
            <option value={'Inactive'}>Inactive</option>
          </select>
        </div>
      </div>
      <div className='UserModalCon1'>
        <div>
          <h2>Language</h2>
          <select value={language} onChange={(e)=>setLanguage(e.target.value)}>
            <option value={''}>English</option>
            <option value={'ES'}>Española</option>
            <option value={'PT'}>Português</option>
            <option value={'FR'}>Français</option>
            <option value={'RU'}>русский</option>
            <option value={'AR'}>عربي</option>
          </select>
        </div>
      </div>
      <div className='UserModalCon4'>
        <div className='FlexCon'/>
        {password || email !== open?.email?
        <>
        <input checked={mail === 'Yes'} onChange={()=>mail === 'Yes'? setMail('No'):setMail('Yes')} type='checkbox'/>
        <p onClick={()=>mail === 'Yes'? setMail('No'):setMail('Yes')}>Send Email</p>
        </>
        :
        <button style={{backgroundColor:"red"}} onClick={()=>{
          if(del){
            setLoad(true)
            onDelete({
              variables:{
                "deleteUserId": open?.id
              }
            }).then(()=>{
              setLoad(false)
              setDel(false)
              setOpen(false)
            }).catch((err)=>{
              setLoad(false)
              alert(err?.message)
            })
          }else if(open?.nations?.length > 0 || open?.regions?.length > 0 || open?.areas?.length > 0){
            alert("Please remove all roles before deleting")
          }else{
            setDel(!del)
          }
        }}>{load? "Deleting" : del? "Confirm?" : "Delete"}</button>
        }
        <button onClick={()=>{
          if(email !== open?.email){
            if(name && password){
              onCreate({
                variables:{
                  "user": {
                    "id": open?.id,
                    "email": email,
                    "name": name,
                    "password": password,
                    "mail":mail,
                    "status":status || '',
                    "language": language || 'EN'
                  }
                }
              }).then(()=>{
                setOpen(false)
                setDel(false)
              }).catch((err)=>{
                alert(err?.message)
              })
            }else{
              alert(name? "Create new password":"Enter name and password")
            }
          }else if(email && name){
            onCreate({
              variables:{
                "user": {
                  "id": open?.id,
                  "email": email,
                  "name": name,
                  "password": password,
                  "mail":mail,
                  "status":status || '',
                  "language": language || 'EN'
                }
              }
            }).then(()=>{
              setOpen(false)
              setDel(false)
            }).catch((err)=>{
              alert(err?.message)
            })
          }else{
            alert("Enter name and email")
          }
        }}>{loading? "Loading" : "Update User"}</button>
      </div>
    </div>
  )
}

function Users({item, setSelect}){
  return(
    <div className='UserCon1' onClick={()=>setSelect(item?.id)}>
      <div className='UserConImg'>
        {item?.verified?<RiCheckboxCircleFill color='#2dcb72'/>:<RiCheckboxCircleFill color='transparent'/>}
        <h2>{item?.name}</h2>
      </div>
      <h3>{item?.email || "--"}</h3>
      <h3>{item?.admin} {item?.nations?.filter((e)=>e?.nd?.id === item?.id)?.length > 0? "ND":null} {item?.nations?.filter((e)=>e?.cw?.id === item?.id)?.length > 0? "CW":null} {item?.nations?.filter((e)=>e?.bc?.id === item?.id)?.length > 0? "BC":null} {item?.regions?.length>0?"RD":null} {item?.areas?.length>0?"AD":null}</h3>
      <h3>{item?.language || 'EN'}</h3>
      <h4 style={{color: item?.status === 'Inactive'?'red':null, backgroundColor:item?.status === 'Inactive'?"#f7e0e0":null}}>{item?.status? item?.status : "Active"}</h4>
    </div>
  )
}