import React, { useEffect, useState } from 'react'
import "./style.css"
import { useMutation } from '@apollo/client'
import { ON_LOGIN } from '../graphql/mutation'

export default function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const [login, {loading}] = useMutation(ON_LOGIN)

  async function OnLogin(e){
    e?.preventDefault()
    if(email && password){
        login({
            variables:{
                "login":{
                    "email": email,
                    "password": password
                }
            }
        }).then((data)=>{
            localStorage.setItem("charter_admin", data?.data?.onAdminLogin)
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }).catch((err)=>{
            setError(err?.message)
        })
    }
  }

  useEffect(()=>{
    if(email || password){
      setError("")
    }
  },[email,password])

  return (
    <div className='Login'>
        <form onSubmit={(e)=>OnLogin(e)}>
            <h2>Log In</h2>
            <h3>to Chartering admin</h3>
            <p>Email Address</p>
            <input value={email} onChange={(e)=>setEmail(e.target.value)} type='email' placeholder='Eg.hello@yfc.org'/>
            <p>Password</p>
            <input value={password} onChange={(e)=>setPassword(e.target.value)} placeholder='• • • • • • • •'/>
            {error? <p style={{color:"red"}}>{error}</p>:null}
            <button>{loading? "Please Wait" : "Log In"}</button>
            {/* <p style={{textAlign:"center"}}>For lost or forgotten password,<br/>please email HQ!</p> */}
        </form>
    </div>
  )
}
