import { Document, Page, Text, StyleSheet, Link, Font } from '@react-pdf/renderer';

export default function MyDoc({charter, questions, titles, GetAns, s3}){
    
    const styles = StyleSheet.create({
        page: {
            display:"flex",
            flexDirection:"column",
            padding:"15mm",
            width:"210mm"
        },
        title:{
            fontSize:20, 
            color:"#fe888b",
            textTransform:"capitalize",
            marginTop:15,
        },
        title1:{
            fontSize:15, 
            color:"#fe888b",
            marginTop:15,
        },
        question:{
            fontSize:15, 
            color:"#000",
            marginTop:15,
        },
        answer:{
            fontSize:15, 
            color:"#777",
            marginTop:10,
            marginLeft:20,
        },
    });

    const arr = ["1","2","3","4","5","6"]
    const arr1 = ["1","2","3"]

    return(
        <>
        <Document title="report">
            <Page size="A4" style={styles.page}>
            <Text style={{fontSize:28, alignSelf:"center", color:"#fe888b"}}>YFCI chartering report for 2022 - 2023</Text>
            <Text style={styles.title}>{titles[0]?.title}</Text>
                <Text style={styles.question}>{questions.s1[0].q1}</Text>
                <Text style={styles.answer}>{charter?.name}</Text>
                {/* <Text style={styles.question}>{questions.s1[0].q2}</Text>
                <Text style={styles.answer}>{charter?.startus}</Text> */}

                <Text style={styles.title}>{titles[1]?.title}</Text>
                <Text style={styles.question}>{questions.s2[0].q}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isConstitution)}</Text>
                <Text style={styles.question}>{charter?.isConstitution === "Y"? questions.s2[0].y : null}</Text>
                <Text style={styles.question}>{charter?.isConstitution === "N"?questions.s2[0].n : null}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isConstitution)}</Text>
                {charter?.isReg === "Y"?
                <>
                <Text style={styles.question}>{questions.s2[1].y.q1}</Text>
                <Text style={styles.answer}>{charter?.regName?charter?.regName:"--"}</Text>
                <Text style={styles.question}>{questions.s2[1].y.q2}</Text>
                <Text style={styles.answer}>{charter?.regNumber?charter?.regNumber:"--"}</Text>
                <Text style={styles.question}>{questions.s2[1].y.q3}</Text>
                <Text style={styles.answer}>{charter?.regType?questions.s2[1].y.ops[charter?.regType]:"--"}</Text>
                <Text style={styles.question}>{questions.s2[1].y.q4}</Text>
                <Text style={styles.answer}>{GetAns(charter?.regUptoDate)}</Text>
                </>
                :
                charter?.isConstitution === "N"?
                <>
                <Text style={styles.question}>{questions.s2[1].n.q}</Text>
                <Text style={styles.answer}>{charter?.regProcess?questions.s2[1].n.ops[charter?.regProcess]:"--"}</Text>
                </>
                : null
                }
                <Text style={styles.question}>{questions.s2[2].q}</Text>
                <Text style={styles.answer}>{charter?.orgUmbrella?charter?.orgUmbrella:null}</Text>
                
                <Text style={styles.title}>{titles[2]?.title}</Text>
                <Text style={styles.question}>{questions.s3[0].q}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isPublicName)}</Text>
                {charter?.isPublicName === "N"? 
                <>
                <Text style={styles.question}>{questions.s3[0].n}</Text>
                <Text style={styles.answer}>{charter?.nameUsed?charter?.nameUsed:"--"}</Text>
                </>
                 : null}
                <Text style={styles.question}>{questions.s3[1].q}</Text>
                <Link style={{marginTop:10, marginLeft:20, fontSize:15, }} src={"https://yfcge.knack.com/yfci-directory#yfci-policies/"}>{questions.s3[1].li}</Link>
                <Text style={styles.answer}>{GetAns(charter?.isbrandComp)}</Text>

                <Text style={styles.title}>{titles[3]?.title}</Text>
                <Text style={styles.question}>{questions.s4[0].t}</Text>
                <Text style={styles.question}>{questions.s4[0].q1}</Text>
                <Text style={styles.answer}>{charter?.mailAddress?charter?.mailAddress:"--"}</Text>
                <Text style={styles.question}>{questions.s4[0].q2}</Text>
                <Text style={styles.answer}>{charter?.streetAddress?charter?.streetAddress:"--"}</Text>
                <Text style={styles.question}>{questions.s4[0].q3}</Text>
                <Text style={styles.answer}>{charter?.conTele?charter?.conTele:"--"}</Text>
                <Text style={styles.question}>{questions.s4[0].q4}</Text>
                <Text style={styles.answer}>{charter?.conEmail?charter?.conEmail:"--"}</Text>
                <Text style={styles.question}>{questions.s4[0].q5}</Text>
                <Text style={styles.answer}>{charter?.conWebsite?charter?.conWebsite:"--"}</Text>
                <Text style={styles.question}>{questions.s4[1].t}</Text>
                <Text style={styles.question}>{questions.s4[1].q1}</Text>
                <Text style={styles.answer}>{charter?.directorName?charter?.directorName:"--"}</Text>
                <Text style={styles.question}>{questions.s4[1].q2}</Text>
                <Text style={styles.answer}>{charter?.directorJobTitle?charter?.directorJobTitle:"--"}</Text>
                <Text style={styles.question}>{questions.s4[1].q3}</Text>
                <Text style={styles.answer}>{charter?.directorEmployStatus?questions.s4[1].ops[charter?.directorEmployStatus]:"--"}</Text>
                <Text style={styles.question}>{questions.s4[1].q4}</Text>
                <Text style={styles.answer}>{charter?.directorPhone?charter?.directorPhone:"--"}</Text>
                <Text style={styles.question}>{questions.s4[1].q5}</Text>
                <Text style={styles.answer}>{charter?.directorEmail?charter?.directorEmail:"--"}</Text>

                <Text style={styles.title}>{titles[4]?.title}</Text>
                <Text style={styles.question}>{questions.s5[0].t}</Text>
                <Text style={styles.question}>{questions.s5[0].q1}</Text>
                <Text style={styles.answer}>{charter?.chairName?charter?.chairName:"--"}</Text>
                <Text style={styles.question}>{questions.s5[0].q2}</Text>
                <Text style={styles.answer}>{charter?.chairPhone?charter?.chairPhone:"--"}</Text>
                <Text style={styles.question}>{questions.s5[0].q3}</Text>
                <Text style={styles.answer}>{charter?.chairEmail?charter?.chairEmail:"--"}</Text>
                <Text style={styles.question}>{questions.s5[1].t}</Text>
                <Text style={styles.question}>{questions.s5[1].q1}</Text>
                <Text style={styles.answer}>{charter?.noBoardMember?charter?.noBoardMember:"--"}</Text>
                <Text style={styles.question}>{questions.s5[1].q2}</Text>
                {/* array */}
                {charter?.boardMemName?.map((item, index)=>{
                    return(
                        <>
                        <Text style={styles.question}>{questions.s5[1].q3}</Text>
                        <Text style={styles.answer}>{item}</Text>
                        <Text style={styles.question}>{questions.s5[1].q4}</Text>
                        <Text style={styles.answer}>{charter?.boardMemPhone[index]}</Text>
                        <Text style={styles.question}>{questions.s5[1].q5}</Text>
                        <Text style={styles.answer}>{charter?.boardMemEmail[index]}</Text>
                        <Text style={styles.question}>{questions.s5[1].q6}</Text>
                        <Text style={styles.answer}>{GetAns(charter?.canVote[index])}</Text>
                        <Text style={styles.question}>{questions.s5[1].q7}</Text>
                        <Text style={styles.answer}>{GetAns(charter?.isBoardMemND[index])}</Text>
                        <Text style={styles.question}>{questions.s5[1].q8}</Text>
                        <Text style={styles.answer}>{GetAns(charter?.isStaff[index])}</Text>
                        <Text style={styles.question}>{questions.s5[1].q9}</Text>
                        <Text style={styles.answer}>{charter?.boardMemPosition[index]}</Text>
                        <Text style={styles.question}>{questions.s5[1].q10}</Text>
                        <Text style={styles.answer}>{charter?.boardMemYears[index]}</Text>
                        </>
                    )
                })}
                {/* array */}
                <Text style={styles.question}>{questions.s5[1].q11}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isBoardMoreFamily)}</Text>
                <Text style={styles.question}>{questions.s5[1].q12}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isBoardMemReElect)}</Text>
                <Text style={styles.question}>{questions.s5[1].q13}</Text>
                <Text style={styles.answer}>{charter?.boardMemTerm?charter?.boardMemTerm:"--"}</Text>
                <Text style={styles.question}>{questions.s5[1].q14}</Text>
                <Text style={styles.answer}>{charter?.boardMemConsTerm?charter?.boardMemConsTerm:"--"}</Text>
                <Text style={styles.question}>{questions.s5[1].q15}</Text>
                <Text style={styles.answer}>{charter?.ADMeetParticipate?charter?.ADMeetParticipate:"--"}</Text>
                <Text style={styles.question}>{questions.s5[1].q16}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isDirectorAppoinment)}</Text>
                <Text style={styles.question}>{questions.s5[1].q17}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isBoardDirectorDiscr)}</Text>
                <Text style={styles.question}>{questions.s5[2].t}</Text>
                <Text style={styles.question}>{questions.s5[2].q}</Text>
                {charter?.boardMeeting?.length === 0? <Text style={styles.answer}>--</Text>:null}
                {charter?.boardMeeting?.map((item, index)=>{
                    return <Text style={styles.answer}>{item}</Text>
                })}
                {/* array */}

                <Text style={styles.title}>{titles[5]?.title}</Text>
                <Text style={styles.question}>{questions.s6[0].q}</Text>
                {charter?.isBankAccount === "Y"?
                <>
                <Text style={styles.question}>{questions.s6[0].y.q1}</Text>
                {charter?.bankDetail?.length === 0? <Text style={styles.answer}>--</Text>:null}
                {charter?.bankDetail?.map((item, index)=>{
                    return <Text style={styles.answer}>{item}</Text>
                })}
                </>
                :null}
                {charter?.isBankAccount === "N"?
                <>
                <Text style={styles.question}>{questions.s6[0].n.q}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isFinancialIntegrity)}</Text>
                </>
                :null}
                <Text style={styles.question}>{questions.s6[1].q1}</Text>
                <Text style={styles.answer}>{charter?.financialStart?charter?.financialStart:"--"}</Text>
                <Text style={styles.question}>{questions.s6[1].q2}</Text>
                <Text style={styles.answer}>{charter?.financialEnd?charter?.financialEnd:"--"}</Text>
                <Text style={styles.question}>{questions.s6[2].q}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isFinancialState)}</Text>
                {charter?.isFinancialState === "Y"?
                <>
                <Text style={styles.question}>{questions.s6[2].y}</Text>
                <Text style={styles.answer}>{charter?.financialPrepare?charter?.financialPrepare:"--"}</Text>
                </> : null}
                <Text style={styles.question}>{questions.s6[3].q}</Text>
                <Link style={{marginTop:10, marginLeft:20, fontSize:15, }} src={"https://yfcge.knack.com/yfci-directory#yfci-policies/"}>{questions.s6[3].li}</Link>
                <Text style={styles.answer}>{GetAns(charter?.isfinancialPolicy)}</Text>
                <Text style={styles.question}>{questions.s6[4].q}</Text>
                <Link style={{marginTop:10, marginLeft:20, fontSize:15, }} src={"https://yfcge.knack.com/yfci-directory#yfci-policies/"}>{questions.s6[4].li}</Link>
                <Text style={styles.answer}>{GetAns(charter?.isFinancialStandards)}</Text>
                {charter?.isFinancialStandards === "N"? 
                <>
                <Text style={styles.question}>{questions.s6[4].n}</Text>
                <Text style={styles.answer}>{charter?.listGFP?charter?.listGFP:"--"}</Text>
                </>
                : null}
                <Text style={styles.question}>{questions.s6[5].q}</Text>
                <Text style={styles.answer}>{GetAns(charter?.is200k)}</Text>
                {charter?.is200k === "Y"?
                <>
                <Text style={styles.question}>{questions.s6[5].y}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isExternalAuditIFRS)}</Text>
                {charter?.isExternalAuditIFRS === "N"?
                <>
                <Text style={styles.question}>{questions.s6[5].q1}</Text>
                <Text style={styles.answer}>{charter?.noFinancialAuditIFRS?charter?.noFinancialAuditIFRS:"--"}</Text>
                </>:null
                }
                </>
                : null}
                {charter?.is200k === "N"?
                <>
                <Text style={styles.question}>{questions.s6[5].n}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isExternalAudit)}</Text>
                {charter?.isExternalAudit === "N"?
                <>
                <Text style={styles.question}>{questions.s6[5].q2}</Text>
                <Text style={styles.answer}>{charter?.noFinancialAudit?charter?.noFinancialAudit:"--"}</Text>
                </>
                : null}
                </>
                : null}
                <Text style={styles.question}>{questions.s6[6].q}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isStaffRetirement)}</Text>
                {charter?.isStaffRetirement === "Y"? 
                <>
                <Text style={styles.question}>{questions.s6[6].y}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isContributionsUptoDate)}</Text>
                </>
                : null}

                <Text style={styles.title}>{titles[6]?.title}</Text>
                <Text style={styles.question}>{questions.s7[0].q1}</Text>
                {charter?.landDiscr?.length === 0? <Text style={styles.answer}>--</Text>:null}
                {charter?.landDiscr?.map((item, index)=>{
                    return(
                        <>
                        <Text style={styles.question}>{questions.s7[0].q2}</Text>
                        <Text style={styles.answer}>{item?item:"--"}</Text>
                        <Text style={styles.question}>{questions.s7[0].q3}</Text>
                        <Text style={styles.answer}>{charter?.landDate[index]}</Text>
                        <Text style={styles.question}>{questions.s7[0].q4}</Text>
                        <Text style={styles.answer}>{charter?.landOwner[index]}</Text>
                        <Text style={styles.question}>{questions.s7[0].q5}</Text>
                        <Text style={styles.answer}>{GetAns(charter?.isLandBoardApprove[index])}</Text>
                        </>
                    )
                })}

                <Text style={styles.title}>{titles[7]?.title}</Text>
                <Text style={styles.question}>{questions.s8[0].q1}</Text>
                <Text style={styles.answer}>{charter?.nationalIncome + charter?.localIncome}</Text>
                <Text style={styles.question}>{questions.s8[0].q2}</Text>
                <Text style={styles.answer}>{charter?.nationalIncome?charter?.nationalIncome:"--"}</Text>
                <Text style={styles.question}>{questions.s8[0].q3}</Text>
                <Text style={styles.answer}>{charter?.localIncome?charter?.localIncome:"--"}</Text>
                <Text style={styles.question}>{questions.s8[1].q1}</Text>
                <Text style={styles.answer}>{charter?.nationalExpenditures + charter?.localExpenditures}</Text>
                <Text style={styles.question}>{questions.s8[1].q2}</Text>
                <Text style={styles.answer}>{charter?.nationalExpenditures?charter?.nationalExpenditures:"--"}</Text>
                <Text style={styles.question}>{questions.s8[1].q3}</Text>
                <Text style={styles.answer}>{charter?.localExpenditures?charter?.localExpenditures:"--"}</Text>
                <Text style={styles.question}>{questions.s8[2].q1}</Text>
                <Text style={styles.answer}>{(charter?.nationalIncome + charter?.localIncome) - (charter?.nationalExpenditures + charter?.localExpenditures)}</Text>
                <Text style={styles.question}>{questions.s8[2].q2}</Text>
                <Text style={styles.answer}>{charter?.preRaisedOwn?charter?.preRaisedOwn:"--"}</Text>
                <Text style={styles.question}>{questions.s8[2].q3}</Text>
                <Text style={styles.answer}>{charter?.supportRaiseNation?charter?.supportRaiseNation:"--"}</Text>
                <Text style={styles.question}>{questions.s8[2].q4}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isRaisePermission)}</Text>
                <Text style={styles.question}>{questions.s8[2].q5}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isFinancialGift)}</Text>
                <Text style={styles.question}>{questions.s8[2].q6}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isApprovedBudget)}</Text>
                {charter?.isApprovedBudget === "Y"?
                <>
                    {charter?.approvedBudgetFile?
                    <Link style={{marginTop:10, marginLeft:20, fontSize:15, }} src={`${s3}${charter?.approvedBudgetFile}`}>{charter?.approvedBudgetFile}</Link>
                    :
                    <Text style={styles.answer}>--</Text>}
                </>
                : null}
                <Text style={styles.question}>{questions.s8[2].q8}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isFundRaising)}</Text>
                {charter?.isFundRaising === "Y"?
                <>
                    {charter?.fundRaisingFile?
                    <Link style={{marginTop:10, marginLeft:20, fontSize:15, }} src={`${s3}${charter?.fundRaisingFile}`}>{charter?.fundRaisingFile}</Link>
                    :
                    <Text style={styles.answer}>--</Text>}
                </>
                : null}
                {/* <Text style={styles.question}>{questions.s8[2].q10}</Text> */}

                <Text style={styles.title}>{titles[8]?.title}</Text>
                <Text style={styles.question}>{questions.s9[0].q1}</Text>
                <Text style={styles.answer}>{charter?.serviceVolunteers?charter?.serviceVolunteers:"--"}</Text>
                <Text style={styles.question}>{questions.s9[0].q2}</Text>
                <Text style={styles.answer}>{charter?.noPaidService?charter?.noPaidService:"--"}</Text>
                <Text style={styles.question}>{questions.s9[0].q3}</Text>
                <Text style={styles.answer}>{charter?.noRegularService?charter?.noRegularService:"--"}</Text>

                <Text style={styles.title}>{titles[9]?.title}</Text>
                <Text style={styles.question}>{questions.s10[0].q1}</Text>
                {charter?.ministryActivity?.map((item, index)=>{
                    return(
                        <>
                        <Text style={styles.question}>{questions.s10[0].q2}</Text>
                        {item?.split("").map((value)=>{
                        return <Text style={styles.answer}>
                            {questions.s10[0].ops[value]}
                        </Text>
                        })}
                        <Text style={styles.question}>{questions.s10[0].q3}</Text>
                        <Text style={styles.answer}>{charter?.noMinistries[index]}</Text>
                        <Text style={styles.answer}>{charter?.ministryNemr[index]}</Text>
                        </>
                    )
                })}
                <Text style={styles.question}>{questions.s10[1].q}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isMedia)}</Text>
                {charter?.isMedia === "Y"?
                <>
                <Text style={styles.question}>{questions.s10[1].y.t}</Text> 
                <Text style={styles.question}>{questions.s10[1].y.q1}</Text>
                {charter?.socialPlatform?.map((item)=>{
                    if(arr.includes(item)){
                        return <Text style={styles.answer}>{questions.s10[1].y.ops1[parseInt(item) - 1]}</Text>
                    }else{
                        return <Text style={styles.answer}>{item}</Text>
                    }
                })
                }
                <Text style={styles.question}>{questions.s10[1].y.q2}</Text>
                <Text style={styles.answer}>{charter?.aboutSocialPlatform?charter?.aboutSocialPlatform:"--"}</Text>
                
                <Text style={styles.question}>{questions.s10[1].y.q3}</Text>
                {charter?.broadcastPlatform?.map((item)=>{
                    if(arr1.includes(item)){
                        return <Text style={styles.answer}>{questions.s10[1].y.ops2[parseInt(item) - 1]}</Text>
                    }else{
                        return <Text style={styles.answer}>{item}</Text>
                    }
                })}
                <Text style={styles.question}>{questions.s10[1].y.q4}</Text>
                <Text style={styles.answer}>{charter?.platformAdditional?charter?.platformAdditional:"--"}</Text>
                </>
                : null
                }

                <Text style={styles.title}>{titles[10]?.title}</Text>
                <Text style={styles.question}>{questions.s11[0].q}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isMinistryPlan)}</Text>
                {charter?.isMinistryPlan === "Y"?
                <>
                <Text style={styles.question}>{questions.s11[0].y}</Text>
                {charter?.ministryPlanFile?
                <Link style={{marginTop:10, marginLeft:20, fontSize:15, }} src={`${s3}${charter?.ministryPlanFile}`}>{charter?.ministryPlanFile}</Link>
                :
                <Text style={styles.answer}>--</Text>
                }
                </>
                : null}
                {charter?.isMinistryPlan === "N"?
                <>
                <Text style={styles.question}>{questions.s11[0].n}</Text>
                <Text style={styles.answer}>{charter?.ministryGoal?charter?.ministryGoal:"--"}</Text>
                </>
                : null}
                
                <Text style={styles.title}>{titles[11]?.title}</Text>
                <Text style={styles.question}>{questions.s12[0].q1}</Text>
                <Text style={styles.answer}>{charter?.noYFCCenters?charter?.noYFCCenters:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q2}</Text>
                <Text style={styles.answer}>{charter?.noYoungDirectsMinistry + charter?.noYoungMediasMinistry}</Text>
                <Text style={styles.question}>{questions.s12[0].q5}</Text>
                <Text style={styles.answer}>{charter?.noFollowDirectsMinistry + charter?.noFollowMediasMinistry}</Text>
                <Text style={styles.question}>{questions.s12[0].q6}</Text>
                <Text style={styles.answer}>{charter?.noYoungResponse?charter?.noYoungResponse:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q7}</Text>
                <Text style={styles.answer}>{charter?.noNoCommitment?charter?.noNoCommitment:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q8}</Text>
                <Text style={styles.answer}>{charter?.noCommitment?charter?.noCommitment:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q9}</Text>
                <Text style={styles.answer}>{charter?.noDiscipleship?charter?.noDiscipleship:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q10}</Text>
                <Text style={styles.answer}>{charter?.noConnectLocalChurch?charter?.noConnectLocalChurch:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q11}</Text>
                <Text style={styles.answer}>{charter?.ministryCollectMethod?charter?.ministryCollectMethod:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q12}</Text>
                <Text style={styles.answer}>{charter?.noYoungLeaders?charter?.noYoungLeaders:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q13}</Text>
                <Text style={styles.answer}>{charter?.noMissionTeam?charter?.noMissionTeam:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q14}</Text>
                <Text style={styles.answer}>{charter?.noParticipantsTeam?charter?.noParticipantsTeam:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q15}</Text>
                <Text style={styles.answer}>{charter?.teamNation?charter?.teamNation:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q16}</Text>
                <Text style={styles.answer}>{charter?.noTeamOtherNation?charter?.noTeamOtherNation:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q17}</Text>
                <Text style={styles.answer}>{charter?.noParticipantsOtherNation?charter?.noParticipantsOtherNation:"--"}</Text>
                <Text style={styles.question}>{questions.s12[0].q18}</Text>
                <Text style={styles.answer}>{charter?.otherTeamNation?charter?.otherTeamNation:"--"}</Text>

                <Text style={styles.title}>{titles[12]?.title}</Text>
                <Text style={styles.question}>{questions.s13[0].q1}</Text>
                <Text style={styles.answer}>{charter?.noChurchPartner?charter?.noChurchPartner:"--"}</Text>
                <Text style={styles.question}>{questions.s13[0].q2}</Text>
                <Text style={styles.answer}>{charter?.noDenominations?charter?.noDenominations:"--"}</Text>
                <Text style={styles.question}>{questions.s13[0].q3}</Text>
                <Text style={styles.answer}>{charter?.noStaffOtherNation?charter?.noStaffOtherNation:"--"}</Text>
                <Text style={styles.question}>{questions.s13[0].q4}</Text>
                <Text style={styles.answer}>{charter?.noStaffYourNation?charter?.noStaffYourNation:"--"}</Text>
                <Text style={styles.question}>{questions.s13[0].q5}</Text>
                <Text style={styles.answer}>{charter?.noPrayerIntercessors?charter?.noPrayerIntercessors:"--"}</Text>

                <Text style={styles.title}>{titles[13]?.title}</Text>
                <Text style={styles.question}>{questions.s14[0].t}</Text>
                {/* <Text style={styles.answer}>{charter?.lastGAYear?charter?.lastGAYear:"--"}</Text> */}
                {/* <Text style={styles.question}>{questions.s14[0].q1}</Text>*/}
                <Text style={styles.question}>{questions.s14[0].q2}</Text>
                <Text style={styles.answer}>{charter?.isLastGADir?charter?.isLastGADir:"--"}</Text> 
                <Text style={styles.question}>{questions.s14[0].q3}</Text>
                <Text style={styles.answer}>{charter?.isLastGAChair?charter?.isLastGAChair:"--"}</Text>
                <Text style={styles.question}>{questions.s14[0].q4}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isCompleteReview)}</Text>
                <Text style={styles.question}>{questions.s14[1].t}</Text>
                <Text style={styles.question}>{questions.s14[1].q1}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isStrategicPlan)}</Text>
                {charter?.isStrategicPlan === "Y"? 
                <>
                <Text style={styles.question}>{questions.s14[1].y1}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isPlanCurrentVersion)}</Text>
                </>
                : null}
                <Text style={styles.question}>{questions.s14[1].q2}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isDirJobDiscr)}</Text>
                {charter?.isDirJobDiscr === "Y"?
                <>
                <Text style={styles.question}>{questions.s14[1].y2}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isDirJobCurrentVersion)}</Text>
                </> : null}
                <Text style={styles.question}>{questions.s14[1].q3}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isBoardFinancePolicy)}</Text>
                {charter?.isBoardFinancePolicy === "Y"?
                <>
                <Text style={styles.question}>{questions.s14[1].y3}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isPolicyCurrentVersion)}</Text>
                </>: null}
                <Text style={styles.question}>{questions.s14[1].q4}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isFundRaisePlan)}</Text>
                {charter?.isFundRaisePlan === "Y"?
                <>
                <Text style={styles.question}>{questions.s14[1].y4}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isFundRaiseCurrentVersion)}</Text>
                </> : null}
                <Text style={styles.question}>{questions.s14[1].q5}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isBoardManual)}</Text>
                {charter?.isBoardManual === "Y"?
                <>
                <Text style={styles.question}>{questions.s14[1].y5}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isManuelCurrentVersion)}</Text>
                </>:null}
                <Text style={styles.question}>{questions.s14[1].q6}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isStaffPolicyManual)}</Text>
                {charter?.isStaffPolicyManual === "Y"?
                <>
                <Text style={styles.question}>{questions.s14[1].y6}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isStaffPolicyCurrentVersion)}</Text>
                </> : null}
                <Text style={styles.question}>{questions.s14[1].q7}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isChildPolicy)}</Text>
                {charter?.isChildPolicy === "Y"?
                <>
                <Text style={styles.question}>{questions.s14[1].y7}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isChildPolicyCurrentVersion)}</Text>
                </>:null}

                <Text style={styles.title}>{titles[14]?.title}</Text>
                <Text style={styles.question}>{questions.s15[0].q}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isSensitive)}</Text>
                {charter?.isSensitive === "Y"?
                <>
                <Text style={styles.question}>{questions.s15[1].t}</Text>
                <Text style={styles.question}>{questions.s15[1].q1}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isYFCIName)}</Text>
                <Text style={styles.answer}>{charter?.YFCIName}</Text>
                <Text style={styles.question}>{questions.s15[1].q2}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isYFCILogo)}</Text>
                <Text style={styles.answer}>{charter?.YFCILogo}</Text>
                <Text style={styles.question}>{questions.s15[1].q3}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isTerminology)}</Text>
                <Text style={styles.question}>{questions.s15[1].q4}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isYFCIWebsiteRemove)}</Text>
                <Text style={styles.question}>{questions.s15[1].q5}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isSocialRefAvoid)}</Text>
                <Text style={styles.question}>{questions.s15[1].q6}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isEmailAvoid)}</Text>
                <Text style={styles.question}>{questions.s15[1].q7}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isDirNameAvoid)}</Text>
                <Text style={styles.question}>{questions.s15[1].q8}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isSharePermit)}</Text>
                <Text style={styles.question}>{questions.s15[1].q9}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isEncryptCom)}</Text>
                <Text style={styles.question}>{questions.s15[2].t}</Text>
                <Text style={styles.question}>{questions.s15[2].q1}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isGlobalDirectory)}</Text>
                <Text style={styles.question}>{questions.s15[2].q2}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isListWebsite)}</Text>
                <Text style={styles.question}>{questions.s15[2].q3}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isReportOffline)}</Text>
                <Text style={styles.question}>{questions.s15[3].t}</Text>
                <Text style={styles.question}>{questions.s15[3].q}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isFinanceInstructions)}</Text>
                <Text style={styles.question}>{questions.s15[4].t}</Text>
                <Text style={styles.question}>{questions.s15[4].q1}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isSafeHouse)}</Text>
                <Text style={styles.question}>{questions.s15[4].q2}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isHaveSafeHouse)}</Text>
                <Text style={styles.question}>{questions.s15[4].q3}</Text>
                <Text style={styles.answer}>{GetAns(charter?.isTraumaTrained)}</Text>
                </> : null}
                <Text style={styles.title}>{titles[15]?.title}</Text>
                <Text style={styles.question}>{questions.s16[0].q1}</Text>
                {charter?.lastAuditFile?
                <Link style={{marginTop:10, marginLeft:20, fontSize:15, }} src={`${s3}${charter?.lastAuditFile}`}>{charter?.lastAuditFile}</Link>
                :
                <Text style={styles.answer}>--</Text>
                }
                <Text style={styles.question}>{questions.s16[0].q2}</Text>
                {charter?.nextBudgetFile?
                <Link style={{marginTop:10, marginLeft:20, fontSize:15, }} src={`${s3}${charter?.nextBudgetFile}`}>{charter?.nextBudgetFile}</Link>
                :
                <Text style={styles.answer}>--</Text>
                }
                <Text style={styles.question}>{questions.s16[0].q3}</Text>
                {charter?.constitutionFile?
                <Link style={{marginTop:10, marginLeft:20, fontSize:15, }} src={`${s3}${charter?.constitutionFile}`}>{charter?.constitutionFile}</Link>
                :
                <Text style={styles.answer}>--</Text>}
            </Page>
        </Document>
        </>
    )
}