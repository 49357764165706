import React, { useEffect, useState } from 'react'
import "./style.css"
import { RiCloseLine, RiEdit2Line, RiFilter2Line } from "react-icons/ri";
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_NATIONS, GET_REPORT } from '../graphql/query';
import MyDoc from './document';
import { PDFDownloadLink } from '@react-pdf/renderer';

export default function Nations() {
  const [select, setSelect] = useState(null)
  const [open, setOpen] = useState(false)
  const {data, loading} = useQuery(GET_NATIONS)

  if(loading){
    return (
      <div className='Loading'>
        Loading...
      </div>
    )
  }
  return (
    <>
    <div className='Home'>
      <div className='HomeHead'>
        NATIONS
        <div className='FlexCon' />
      </div>
      <div className='UserDiv'>
        <div className='NationDivLeft'>
          <div className='UserSearchCon'>
            <input placeholder='Search nation' />
            <div className='FlexCon' />
            <button><RiFilter2Line/>Filter</button>
          </div>
          <div className='UserCon'>
            <div className='UserHead'>
              All Nations
              <h4>132</h4>
              <div className='FlexCon' />
              {/* <button onClick={()=>setOpen(true)}><RiAddCircleLine/> Create User</button> */}
            </div>
            <div className='NationHead1'>
              <h2>Name</h2>
              <h2>Progress</h2>
              <h2>ND name</h2>
              <h2>ND sign</h2>
              <h2>BC name</h2>
              <h2>BC sign</h2>
            </div>
            {data?.getCharterAnuals?.map((item, index)=>{
              return(
                <User key={index} item={item} setSelect={setSelect} charter={data?.getCharters} setOpen={setOpen}/>
              )
            })}
          </div>
        </div>
        <div className='UserDivRight'>
            {select?
            <>
            <div className='UserDivRightProfile'>
                <h2>{select?.country}</h2>
                <div className='FlexCon'/>
                <div className='UserDivRightEdit'>
                    <RiEdit2Line/>
                </div>
            </div>
            <div className='UserDivRightDiv1'>
                <button onClick={()=>setOpen(select?.country)}>View Report</button>
            </div>
            </> : null}
        </div>
      </div>
    </div>
    {open?<Report setOpen={setOpen} open={open}/> : null}
    </>
  )
}

function Report ({setOpen, open}){
  const [getData,{loading, data}] = useLazyQuery(GET_REPORT)
  useEffect(()=>{
    if(open){
      getData({
        variables:{
          "getCharterNationId": open
        }
      }).then((dat)=>{
      }).catch((err)=>{
        console.log(err);
      })
    }
  },[open])

  const s3 = 'https://chartering-storage153641-staging.s3.eu-west-2.amazonaws.com/public/'
  const questions = EN_Ques
  const titles = EN.side
  const GetAns = (val) =>{
    if(val === "Y"){
        return questions.yes
    }else if(val === "N"){
        return questions.no
    }else{
        return "--"
    }
   }

  return(
    <div className={'UserModal UserModalOpen'}>
      <div className='UserModalCon2'>
        <RiCloseLine className='UserModalClose' size={18} onClick={()=>setOpen(false)}/>
        <div className='UserModalHead'>
          Report
        </div>
        {loading?
        <div className='UserModalLoad'>Loading</div>
        :
        <>
        <MyDoc charter={data?.getCharterNation} questions={questions} titles={titles} GetAns={GetAns} lang={"EN"} s3={s3}/>
        <div className='UserModalCon1'>
          <div className='FlexCon'/>
          <button style={{marginRight:15}} onClick={()=>setOpen(false)}>Close</button>
          <PDFDownloadLink style={{textDecoration:"none"}} document={<MyDoc charter={data?.getCharterNation} questions={questions} titles={titles} GetAns={GetAns} lang={"EN"} s3={s3}/>} fileName={`${open}-2022.pdf`}>
            {({ blob, url, loading, error }) =>{
                return(
                <div className={"Download_Btn_Con"}>
                    <button style={{marginTop:0}}>{loading? "Loading" : "Download"}</button>
                </div>
                )
            }
            }
        </PDFDownloadLink>
        </div>
        </>
        }
        </div>
    </div>
  )
}

function User({item, setSelect, charter, setOpen}){
  const charters = charter?.filter((e)=>e?.id === item?.anualCharteringId)[0]
  
  return(
    <div className='NationCon1' onClick={()=>setSelect(charters)}>
      <div className='UserConImg'>
        <h2>{charters?.country}</h2>
      </div>
      {item?.directorSignature? <h4>Filled</h4>: charters? <h5 style={{backgroundColor:"#fbf1e1", color:"#f5b748"}}>Filling</h5> : <h5>--</h5>}
      <h3>{charters?.user?.NDName}</h3>
      {item?.directorSignature?<h4>Yes</h4>:<h5>No</h5>}
      <h3>{charters?.user?.name}</h3>
      {item?.chairSignature?<h4>Yes</h4>:<h5>No</h5>}
    </div>
  )
}

const EN_Ques = {
  add:"+Add more",
  auto:"(Automatically calculates)",
  est:"Estimated data",
  act:"Actual data",
  yes:"Yes",
  no:"No",
  agree:"Agree",
  disagree:"Disagree",
  comment:"Comment",
  s1:[
      {
          q1:"Charter Name",
          q2:"Charter Status"
      },
  ],
  s2:[
      {
          q:"1.1. Is there a YFC Constitution in place for your national organization?", 
          y: "Does your national YFC Constitution contain a statement indicating compliance with the YFCI Constitution?", 
          n:"Do you acknowledge that you are in agreement with the YFCI Constitution and are willing to be governed by it in your relationship with the international family of YFC?" 
      },
      {
          q:"1.2. Is your national organization officially registered with the government?",
          y:{
              t:"Give us details on your registration",
              q1:"What is your nation’s official government registration name?",
              q2:"What is your nation’s official government registration number?",
              ops:["Charity", "NGO", "Humanitarian-aid", "Organization", "Mission", "Other"],
              q3:"What is your designation?",
              q4:"Is your government registration up to date?"
          },
          n:{
              q:"Where are you in this process?",
              ops:["Not yet begun", "Documents being finalized", "Documents with government", "Official registration not possible"]
          }
      },
      {
          q:"1.3. Do you operate under another organizational umbrella? If so, what is the name of the organization?"
      }
  ],
  s3:[
      {
          q:"1.4. Do you publicly refer to your national organization as Youth for Christ or its translated equivalent?",
          n:"What name is used (translated into English)? (For example, Youth First Concerns)"
      },
      {
          q:"1.5. Is your national organization’s branding in compliance with the",
          li:"YFCI Branding Guide"
      }
  ],
  s4:[
      {
          t:"National Office",
          q1:"1.6. Mailing Address",
          q2:"1.7. Street Address",
          q3:"1.8. Telephone",
          q4:"1.9. Email",
          q5:"1.10. Website(s)"
      },
      {
          t:"National Director/Coordinator",
          q1:"1.11. National Director/Coordinator Name",
          q2:"1.12. What is his or her job title?",
          q3:"1.13. What is his or her employment status?",
          ops:["Full-time Employee", "Part-time Employee", "Full-time Volunteer", "Part-time Volunteer"],
          q4:"1.14. Phone",
          q5:"1.15. Email"
      }
  ],
  s5:[
      {
          t:"Board Chairperson Contact Information - provide personal info as allowed by the laws of your nation",
          q1:"2.1. National Board Chairperson Name",
          q2:"2.2. Board Chairperson's Phone",
          q3:"2.3. Board Chairperson's Email"
      },
      {
          t:"Board Members",
          q1:"2.4. How many people served as voting members of the National Board or its equivalent? (Voting members must not include National Director’s immediate family members or YFC Staff that are accountable to the National Director as per YFC Standards of Chartering)",
          q2:"2.5. Board Member Information - provide personal information as allowed by the laws of your nation",
          q3:"Name",
          q4:"Phone",
          q5:"Email",
          q6:"Does this member have a vote?",
          q7:"Immediate family member of the ND? (Immediate family member - spouse, parent, brother, sister, son, daughter or in-laws)",
          q8:"Staff?",
          q9:"Board Member Position",
          q10:"Years served as a Board Member",
          q11:"2.6. Are there more than two immediate family members on the National Board? (Immediate family member - spouse, parent, brother, sister, son, daughter or in-laws)",
          q12:"2.7. Is there a system for re-electing board members?",
          q13:"2.8. What is the board member’s length of term?",
          q14:"2.9. What is the number of allowed consecutive terms for a board member?",
          q15:"2.10. Does the AD / RD participate in the board meetings?",
          ops:["Always",
              "Sometimes",
              "Never",
              ],
          q16:"2.11. Is the appointment and dismissal of the National Director carried out by the National Board in consultation with the Area Director?",
          q17:"2.12. Does the National Board set the job description, term of service, and contract of employment or letter of agreement of the National Director?"

      },
      {
          t:"Board Meetings",
          q:"2.13. Collect Board Meeting Information: dates of the Board meetings held when a quorum of Board Members was present",
      }
  ],
  s6:[
      {
          q:"3.1. Does your YFC National Organization have dedicated bank account(s)?",
          y:{
              q1:"List bank(s), account owner(s), and individuals authorized to sign or operate these accounts and their position with YFC.",
              q2:"Bank account details"
          },
          n:{
              q:"Does your nation have an arrangement approved by your Area Office that ensures financial integrity?"
          }
          },
          {
              q1:"3.2. When does your financial year start?",
              q2:"3.2. When does your financial year end?"
          },
          {
              q:"3.3. Are regular financial statements prepared?",
              y:"How often are they prepared?"
          },
          {
              q:"3.4. Do you have a board approved financial policy that establishes proper systems, records and controls in alignment with the YFCI",
              li:"Global Financial Policy"
          },
          {
              q:"3.5. Is your program following all of the minimum standards outlined in the",
              li:"Global Financial Policy",
              n:"Please list which items of the GFP you are not aligned with. (Section number and line item)"
          },
          {
              q:"3.6. Is your national organization receiving total revenue greater than the equivalent of $200k USD?",
              y:"Are you performing an external financial audit fulfilling IFRS or GAAP standards or equivalent in your nation?",
              n:"Are you performing an external financial audit OR a financial review by a public accountant?",
              q1:"Why have you not had a financial audit?",//other lang add
              q2:"Why have you not had a financial audit or review?",//other lang add
          },
          {
              q:"3.7. Do you have a staff retirement/gratuity/provident fund set aside?",
              y:"Are all contributions to this fund up to date?"
          }
  ],
  s7:[
      {
          q1:"3.8. Please include all YFC property registered under the name of the YFC organization and/or under personal registration",
          q2:"Description",
          q3:"Date of purchase",
          q4:"Owner (Name on title deed)",
          q5:"Has the National Board approved this purchase?"
      }
  ],
  s8:[
      {
          q1:"4.1. Total income to all YFC work in your nation (including monies raised by staff for their personal support)",
          q2:"4.1.a. Income for all YFC NATIONAL OFFICE Operations and Programs (including all monies raised by staff for their personal support and any financial assistance received from YFCI or Area Office)",
          q3:"4.1.b. Income for all LOCAL YFC Centres Operations and Programs (including all monies raised by staff for their personal support)"
      },
      {
          q1:"4.2 Total expenditures for all YFC work in your nation (including all monies expended to staff for their personal support)",
          q2:"4.2.a. Expenditures for all YFC NATIONAL OFFICE Operations and Programs (including all monies expended to staff for their personal support)",
          q3:"4.2.b. Expenditures for all LOCAL YFC Centres Operations and Programs (including all monies expended to staff for their personal support)"
      },
      {
          q1:"4.3. Amount of surplus or deficit between total income and total expenditures",
          q2:"4.4. What percentage of the above income was raised within your own nation?",
          q3:"4.5. In which other nations did you raise support?",
          q4:"4.6. Did you receive permission from the National YFC program(s) in the above nation(s)?",
          q5:"4.7. Did you give financial gifts to support other YFC ministries and/or staff outside of your nation?",
          q6:"4.8. Do you have a board approved annual budget for your nation?",
          q7:"4.9. Do you have a written fundraising plan for your nation?",
          q8:"Please upload here",
          q9:"Please upload here",
          q10:"Anything additional you would like to say about this section?"
      }
  ],
  s9:[
      {
          q1:"5.1. Total number of staff and volunteers serving in your nation?",
          q2:"5.1.a. Of this number, how many are paid a salary or hourly wage for their time (not only ministry expenses)?",
          q3:"5.1.b. Of this number, how many are volunteers serving on a regular basis?"
      }
  ],
  s10:[
      {
          q1:"6.1. List the ministry models used in your ministry this past year.",
          q2:"Which of the following ministry activities were?",
          ops:["Please select", "Evangelism", "Discipleship", "Prayer", "Social Involvement"],
          q3:"Name of ministries",
      },
      {
          q:"6.2. Do you have a media ministry?",
          y:{
              t:"What platforms are you using?",
              q1:"Social",
              ops1:["DASK", "Facebook", "Instagram", "YouTube", "WhatsApp", "Discord", "TikTok", "Other"],
              q2:"Anything additional you would like to say about this section?",
              q3:"Broadcast",
              ops2:["Radio","Television","Podcast","Other"],
              q4:"Anything additional you would like to say about this section?"
          }
      }
  ],
  s11:[
      {
          q:"6.3. Do you have a written Strategic Ministry Plan?",
          y:"Please upload here",
          n:"Please outline your four main ministry goals for the current year"
      }
  ],
  s12:[
      {
          q1:"6.4. Number of YFC Centres (Branches/Chapters) in your nation",
          q2:"6.5. Number of young people contacted by direct and media ministry (The number of young people who had some personal and direct form of contact with YFC staff or volunteers either individually or through a YFC ministry program.) If a particular young person attended 12 YFC events throughout the year, that is ONE PERSON for the purpose of this report.",
          q3:"By direct ministry",
          q4:"By media ministry",
          q5:"6.6. Number of young people given an opportunity to make an informed decision to become a follower of Jesus Christ through direct and media ministry",
          q6:"6.7. Number of young people who responded to the opportunity to be a follower of Jesus Christ through direct ministry and media ministry",
          q7:"6.7.a. Number of young people who indicated they wanted to know more about having a relationship with Jesus and were open to explore this further, but made no commitment",
          q8:"6.7.b. Number of young people who made a commitment to follow Jesus",
          q9:"6.8. Number of young people listed in 6.7.b. involved in follow-up/discipleship (The number who actually entered into a discipleship relationship with YFC staff, volunteers or local church members, or through an on-line discipleship ministry.)",
          q10:"6.9. Number of young people your ministry connected to a local church",
          q11:"6.10. What methods do you use to track/collect above-mentioned data?",
          q12:"6.11. Number of young leaders in a formalized Young Leader Development process",
          q13:"6.12. Number of Mission Teams (YFC ministries, churches, other) sent to other nations",
          q14:"6.12.a. Number of participants on the teams sent",
          q15:"6.12.b. Nations where they went",
          q16:"6.13. Number of Missions Teams received from other nations received from other nations",
          q17:"6.13.a. Number of participants on teams received",
          q18:"6.13.b. Nations where the team(s) came from"
      }
  ],
  s13:[
      {
          q1:"7.1. Number of churches with whom you have some kind of mutually understood partnership",
          q2:"7.2. Number of denominations you work with in your nation",
          q3:"7.3. Number of staff and volunteers sent from your nation to reside and serve in another nation",
          q4:"7.4. Number of staff and volunteers received from another nation who reside and serve in your nation",
          q5:"7.5. Number of formally identified prayer intercessors praying for YFC"
      }
  ],
  s14:[
      {
          t:"8.1. Last General Assembly attended",
          q1:"Year",
          q2:"National Director",
          q3:"Board Chair",
          q4:"8.2. Has your RD or AD debriefed or together completed the National Program Self-Review and YFC National Program Review Tool of YFCI Services with you in the last 2 years?"
      },
      {
          t:"8.3. Do you have?",
          q1:"Strategic Ministry Plan",
          y1:"Is current version on file with the Area Office?",
          q2:"National Director Job Description",
          q3:"Board Approved Financial Policy (systems, records & controls)",
          q4:"Written Fundraising Plan",
          q5:"Board Manual",
          q6:"Staff Policy Manual",
          q7:"Child Protection Policy",
      }
  ],
  s15:[
      {
          q:"9.1. Is your nation a sensitive nation?"
      },
      {
          t:"COMMUNICATION",
          q1:"YFCI Communications should not contain the names 'Youth For Christ' or 'Youth For Christ International'",
          q2:"Communication coming from YFCI should not include the YFC logo",
          q3:"YFCI communication should use safe, non-specific terminology when referencing issues of faith, the movement of YFC or our ministry activities",
          q4:"All references to the YFCI website should be removed from all communications",
          q5:"YFCI should avoid references to our nation in their social media since such references could establish an organizational relationship",
          q6:"Communication using email should be avoided in all cases",
          q7:"YFCI should not include the names of the national director, board members or staff in all communications",
          q8:"YFCI will not share information, stories, pictures, videos of activity in your nation without permission of the Regional Director or National Director",
          q9:"YFCI communication should only use end-to-end encryption through a secure messaging service",
      },
      {
          t:"CHARTERING",
          q1:"YFCI should not list our nation in the Global Directory (YFC internal only)",
          q2:"YFCI should not list our nation on their website",
          q3:"Annual chartering and ministry reporting can be done only through a secure, offline submission"
      },
      {
          t:"FINANCES",
          q:"We have special financial transfer instructions YFCI needs to be aware of"
      },
      {
          t:"MEMBER CARE",
          q1:"Safe House and Emergency Protocols are needed for our location",
          q2:"Safe House and Emergency Protocols have been established and have been shared with our Area or Regional office",
          q3:"Trauma-trained advocates have been identified and are accessible for our location"
      }
  ],
  s16:[
      {
          q1:"Financials - Last Audit",
          q2:"Financials - Next year’s budget",
          q3:"Constitution"
      }
  ]
}

const EN = {
  side:[
      {title:"charter information", done:false},
      {title:"constitution and registration", done:false},
      {title:"national organization details", done:false},
      {title:"contact information", done:false},
      {title:"national board information", done:false},
      {title:"accounting details", done:false},
      {title:"YFC property owned - land or buildings", done:false},
      {title:"financial information (Please compute all financial figures in your national currency)", done:false},
      {title:"personnel information", done:false},
      {title:"ministry models", done:false},
      {title:"ministry goals", done:false},
      {title:"ministry information (the numbers section)", done:false},
      {title:"partnerships", done:false},
      {title:"other", done:false},
      {title:"sensitive nations", done:false},
      {title:"additional uploads", done:false},
  ],
  save:"Save Changes",
  next:"Next",
  previous:"Previous",
  right:[
      { t: "PROFILE SECTION" ,
       q1: "Country:" ,
       q3: "Director name:",
       q4: "Director email:" ,
       q5: "Chair of Board Name:",
       q6: "Chair of Board Email:",
       q7: "Regional Director:",
       q8: "Area Director:"
      },
       {t: "CHECK PROGRESS",
       q1: "Status:" ,
       q2: "Director submited:",
       q3: "Chair signed:",
       q4: "Payment date:",
       q5: "Payment method:" },
    ]
}