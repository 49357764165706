import React, { useEffect, useState } from 'react'
import "./style.css"
import { RiAddCircleLine, RiCloseLine, RiEdit2Line } from "react-icons/ri";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_LOGS, GET_NATION, GET_ONE_NATION, GET_USER } from '../graphql/query';
import { CREATE_LOG, CREATE_NATION, NATION_UPDATE, UPDATE_ANUALS } from '../graphql/mutation';
import moment from 'moment';

export default function Nation({user}) {
    const [selectId, setSelect] = useState(null)
    const [edit, setEdit] = useState(false)
    const [open, setOpen] = useState(false)
    const { data, loading } = useQuery(GET_NATION,{
        variables:{
            "year": "2023"
        }
    })

    const select = data?.getNations?.filter((e)=>e?.id === selectId)[0]

    const [search, setSearch] = useState([])

    const handleSearch = (query) => {
        const filteredResults = data?.getNations?.filter(item =>
        item?.name?.toLowerCase().includes(query.toLowerCase())
        );

        setSearch(filteredResults);
    };

    const GetAnswer = (id) =>{
        return data?.getQuestions?.find((e)=> e?.nationId === id) || null
    }

    if (loading) {
        return (
            <div className='Loading'>
                Loading...
            </div>
        )
    }
    return (
        <>
            <div className='Home'>
                <div className='HomeHead' onClick={()=>console.log(data?.getQuestions)}>
                    NATIONS
                    <div className='FlexCon' />
                </div>
                <div className='UserDiv'>
                    <div className='NationDivLeft'>
                        <div className='UserSearchCon'>
                            <input onChange={(e)=>handleSearch(e?.target?.value)} placeholder='Search nation' />
                            <div className='FlexCon' />
                            {user?.role === 'super admin'?
                            <button onClick={()=>setOpen(true)}><RiAddCircleLine />Create</button>
                            : null}
                        </div>
                        <div className='UserCon'>
                            <div className='UserHead'>
                                All Nations
                                <h4>{data?.getNations?.length}</h4>
                                <div className='FlexCon' />
                                {/* <button onClick={()=>setOpen(true)}><RiAddCircleLine/> Create User</button> */}
                            </div>
                            <div className='NationHead2'>
                                <h2>Name</h2>
                                <h2>Status</h2>
                                {/* <h2>Region</h2> */}
                                <h2>Progress</h2>
                                <h2>BC Sign Date</h2>
                                <h2>Payment</h2>
                                {/* <h2>BC name</h2>
                                <h2>ND name</h2> */}
                            </div>
                            {search?.length>0?
                            search?.map((item, index)=>{
                            return <User key={index} answer={GetAnswer(item?.id)} item={item} setSelect={setSelect}/>
                            })
                            :
                            data?.getNations?.slice()?.sort((a,b)=>{
                                if ( a.name < b.name ){
                                    return -1;
                                }
                                if ( a.name > b.name ){
                                    return 1;
                                }
                                return 0;
                                })?.map((item, index) => {
                                return (
                                    <User key={index} answer={GetAnswer(item?.id)} item={item} setSelect={setSelect} />
                                )
                            })}
                        </div>
                    </div>
                    <div className='UserDivRight'>
                        {select ?
                            <>
                                <div className='UserDivRightProfile'>
                                    <h2>{select?.name}</h2>
                                    <div className='FlexCon' />
                                    {user?.role === 'super admin'?
                                    <div className='UserDivRightEdit' onClick={() => setEdit(select)}>
                                        <RiEdit2Line />
                                    </div>
                                    : null}
                                </div>
                                <div className='NationDivRightDiv'>
                                    <h4>Status:</h4>
                                    <h5>{select?.status || '--'}</h5>
                                </div>
                                <div className='NationDivRightDiv'>
                                    <h4>Region:</h4>
                                    <h5>{select?.region?.name || '--'}</h5>
                                </div>
                                <div className='NationDivRightDiv'>
                                    <h4>Currency:</h4>
                                    <h5>{select?.currency || '--'}</h5>
                                </div>
                                <div className='NationDivRightDiv'>
                                    <h4>Exchange:</h4>
                                    <h5>{select?.exchange || '--'}</h5>
                                </div>
                                <div className='NationDivRightDiv'>
                                    <h4>ND name:</h4>
                                    <h5>{select?.nd?.name || '--'}</h5>
                                </div>
                                <div className='NationDivRightDiv'>
                                    <h4>BC name:</h4>
                                    <h5>{select?.bc?.name || '--'}</h5>
                                </div>
                                <div className='NationDivRightDiv'>
                                    <h4>CW name:</h4>
                                    <h5>{select?.cw?.name || '--'}</h5>
                                </div>
                                <div className='NationDivRightDiv'>
                                    <h4>BC signed date</h4>
                                    <h5>{GetAnswer(select?.id)?.anual?.date_submitted? moment(GetAnswer(select?.id)?.anual?.date_submitted * 1).format("LL"): "--"}</h5>
                                </div>
                                <Logs select={select}/>
                            </> : null}
                    </div>
                </div>
            </div>
            {edit ? <Report setOpen={setEdit} open={edit} anual={GetAnswer(select?.id)}/> : null}
            <div className={open?'UserModal UserModalOpen':'UserModal'}>
              <CreateNation setOpen={setOpen} open={open} />
            </div>
        </>
    )
}

function Logs({select}){
    const [getLogs, {data, loading}] = useLazyQuery(GET_LOGS)

    useEffect(()=>{
        if(select){
            getLogs({
                variables:{
                    "getLogsId": select?.id
                }
            })
        }
    },[select])

    if(loading){
        return(
            <div className='LogLoading'>
                Loading Logs...
            </div>
        )
    }
    return(
        <table>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Log</th>
                </tr>
            </thead>
            <tbody>
                {data?.getLogs?.length == 0?
                <tr>
                    <td style={{width:30}}>--</td>
                    <td>--</td>
                    <td>--</td>
                </tr>
                :
                data?.getLogs?.map((item, index)=>{
                    return(
                        <tr key={index}>
                            <td style={{width:30}}>{item?.type}</td>
                            <td>{moment(item?.date).format('lll')}</td>
                            <td>{item?.log}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

function Report({ setOpen, open, anual }) {
    const [name, setName] = useState("")
    const [status, setStatus] = useState("")
    const [exchange, setExchange] = useState("")
    const [bc, setBc] = useState("")
    const [nd, setNd] = useState("")
    const [cw, setCw] = useState("")
    const [region, setRegion] = useState("")
    const [currency, setCurrency] = useState("")
    const [payStatus, setPayStatus] = useState("")
    const [payComment, setPayComment] = useState("")

    const [getData, { loading, data }] = useLazyQuery(GET_USER)

    const [onUpdateAnual] = useMutation(UPDATE_ANUALS,{
        refetchQueries:[
            {
                query:GET_ONE_NATION,
                variables:{
                    "year": "2023",
                    "getNationId": open?.id
                }
            }
        ]
    })
    
    const [onUpdate] = useMutation(NATION_UPDATE,{
        refetchQueries:[
            {
                query:GET_ONE_NATION,
                variables:{
                    "year": "2023",
                    "getNationId": open?.id
                }
            }
        ]
    })
    const [load, setLoad] = useState(false)

    const [onLogCreate] = useMutation(CREATE_LOG,{
        refetchQueries:[
            {
                query: GET_LOGS,
                variables:{
                    "getLogsId": open?.id
                }
            }
        ]
    })

    useEffect(()=>{
        if(open){
            setName(open?.name)
            setStatus(open?.status)
            setExchange(open?.exchange)
            setNd(open?.nd?.id)
            setBc(open?.bc?.id)
            setCw(open?.cw?.id)
            setCurrency(open?.currency)
            setRegion(open?.region?.id)
            setPayStatus(anual?.anual?.paying_how)
            setPayComment(anual?.anual?.payment_comment)
            getData()
        }
    },[open])

    const getUserId = (id) =>{
        return data?.getUsers?.find((e)=> e?.id == id) || {name: "None"}
    }

    return (
        <div className={'UserModal UserModalOpen'}>
            <div className='UserModalCon' style={{minWidth:300}}>
                <div className='UserModalHead'>
                    Edit Nation
                </div>
                <RiCloseLine className='UserModalClose' size={18} onClick={() => setOpen(false)} />
                {loading?
                <p style={{margin:15}}>Loading</p>
                :
                <>
                <div className='UserModalCon1'>
                    <div>
                        <h2>Nation Name</h2>
                        <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Country name' />
                    </div>
                    <div className='UserModalInput'>
                        <h2>Status</h2>
                        {/* <input value={status} onChange={(e)=>setStatus(e.target.value)} placeholder='Status'/> */}
                        <select value={status} onChange={(e)=>setStatus(e.target.value)}>
                            <option value={""}>Select Status</option>
                            <option value={"Associate Charter"}>Associate Charter</option>
                            <option value={"Pioneer Charter"}>Pioneer Charter</option>
                            <option value={"Full Charter"}>Full Charter</option>
                            <option value={"Target Charter"}>Target Charter</option>
                            <option value={"Not currently chartered"}>Not currently chartered</option>
                        </select>
                    </div>
                </div>
                <div className='UserModalCon1'>
                    <div>
                        <h2>Select ND</h2>
                        <select value={nd} onChange={(e)=>setNd(e?.target?.value)}>
                            <option>Select ND</option>
                            {data?.getUsers?.slice()?.sort((a,b)=>{
                                if ( a.name < b.name ){
                                    return -1;
                                }
                                if ( a.name > b.name ){
                                    return 1;
                                }
                                return 0;
                                })?.map((item, index)=>{
                                return <option key={index} value={item?.id}>{item?.name}</option>
                            })}
                        </select>
                    </div>
                    <div className='UserModalInput'>
                        <h2>Select BC</h2>
                        <select value={bc} onChange={(e)=>setBc(e?.target?.value)}>
                            <option>Select BC</option>
                            {data?.getUsers?.slice()?.sort((a,b)=>{
                                if ( a.name < b.name ){
                                    return -1;
                                }
                                if ( a.name > b.name ){
                                    return 1;
                                }
                                return 0;
                                })?.map((item, index)=>{
                                return <option key={index} value={item?.id}>{item?.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className='UserModalCon1'>
                    <div>
                        <h2>Select CW</h2>
                        <select value={cw} onChange={(e)=>setCw(e?.target?.value)}>
                            <option>Select CW</option>
                            {data?.getUsers?.slice()?.sort((a,b)=>{
                                if ( a.name < b.name ){
                                    return -1;
                                }
                                if ( a.name > b.name ){
                                    return 1;
                                }
                                return 0;
                                })?.map((item, index)=>{
                                return <option key={index} value={item?.id}>{item?.name}</option>
                            })}
                        </select>
                    </div>
                    <div className='UserModalInput'>
                    <h2>Payment Status</h2>
                        <select value={payStatus} onChange={(e)=>setPayStatus(e?.target?.value)}>
                            <option value={""}>Select Status</option>
                            <option value={"Outstanding"}>Outstanding</option>
                            <option value={"Done"}>Done</option>
                        </select>
                    </div>
                </div>
                <div className='UserModalCon1'>
                    <div>
                        <h2>Payment Comments</h2>
                        <textarea value={payComment} onChange={(e)=>setPayComment(e?.target?.value)}/>
                    </div>
                </div>
                <div className='UserModalCon1'>
                    <div>
                        <h2>Exchange</h2>
                        <input value={exchange} onChange={(e) => setExchange(e.target.value)} placeholder='Exchange rate' />
                    </div>
                    <div className='UserModalInput'>
                        <h2>Currency</h2>
                        <input value={currency} onChange={(e)=>setCurrency(e.target.value)} placeholder='Currency'/>
                    </div>
                </div>
                <div className='UserModalCon1'>
                    <div>
                        <h2>Language</h2>
                        <select>
                            <option value={'EN'}>English</option>
                            <option value={'ES'}>Spanish</option>
                            <option value={'FR'}>French</option>
                            <option value={'PR'}>Portuguese</option>
                            <option value={'RU'}>Russian</option>
                            <option value={'AR'}>Arabic</option>
                        </select>
                    </div>
                    <div className='UserModalInput'>
                        <h2>Region</h2>
                        <select value={region} onChange={(e)=>setRegion(e?.target?.value)}>
                            <option>Select Region</option>
                            {data?.getRegions?.slice()?.sort((a,b)=>{
                                if ( a.name < b.name ){
                                    return -1;
                                }
                                if ( a.name > b.name ){
                                    return 1;
                                }
                                return 0;
                                })?.map((item, index)=>{
                                return <option key={index} value={item?.id}>{item?.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className='UserModalCon1'>
                    <div className='FlexCon'/>
                    <button onClick={()=>{
                        setLoad(true)
                        if(payStatus != anual?.anual.paying_how || payComment != anual?.anual.payment_comment){
                            onUpdateAnual({
                                variables:{
                                    "year": "2023",
                                    "anual": {
                                        "paying_how": payStatus,
                                        "payment_comment": payComment,
                                        "charterId": anual?.id
                                    }
                                }
                            }).then((e)=>{
                                onLogCreate({
                                    variables:{
                                        "log": {
                                          "date": new Date().toString(),
                                          "log": payStatus != anual?.anual.paying_how?  `Payment status chanaged to ${payStatus || "None"} ` : '' + payComment != anual?.anual.payment_comment? `Payment comment added` : '',
                                          "nationId": open?.id,
                                          "type": 'Nation Update'
                                        }
                                    }
                                }).then(({data})=>{
                                    console.log(data);
                                    setLoad(false)
                                    setOpen(false)
                                    setName("")
                                    setStatus("")
                                    setExchange("")
                                    setNd("")
                                    setBc("")
                                    setCw("")
                                    setCurrency("")
                                    setRegion("")
                                })
                            }).catch((err)=>{
                                setLoad(false)
                                console.log(err);
                            })
                        } else{
                            onUpdate({
                            variables:{
                                "updateNationId": open?.id,
                                "nation": {
                                    "bc": bc || "",
                                    "currency": currency || "",
                                    "language": open?.language || "",
                                    "exchange": exchange || "",
                                    "name": name || "",
                                    "nd": nd || "",
                                    "cw": cw || "",
                                    "status": status,
                                    "language": open?.language || "",
                                    "region": region || "",
                                }
                            }
                            }).then(()=>{
                                if(open?.nd?.id !== nd || open?.bc?.id !== bc || open?.cw?.id !== cw){
                                    onLogCreate({
                                        variables:{
                                            "log": {
                                            "date": new Date().toString(),
                                            "log": open?.nd?.id !== nd? `ND changed from ${getUserId(open?.nd?.id)?.name} to ${getUserId(nd)?.name} ` : '' + open?.bc?.id !== bc? `ND changed from ${getUserId(open?.bc?.id)?.name} to ${getUserId(bc)?.name} ` : '' + open?.cw?.id !== cw? `CW changed from ${getUserId(open?.cw?.id)?.name} to ${getUserId(cw)?.name} ` : '',
                                            "nationId": open?.id,
                                            "type": 'Nation Update'
                                            }
                                        }
                                    }).then(({data})=>{
                                        console.log(data);
                                        setLoad(false)
                                        setOpen(false)
                                        setName("")
                                        setStatus("")
                                        setExchange("")
                                        setNd("")
                                        setBc("")
                                        setCw("")
                                        setCurrency("")
                                        setRegion("")
                                    })
                                }else{
                                    setLoad(false)
                                    setOpen(false)
                                    setName("")
                                    setStatus("")
                                    setExchange("")
                                    setNd("")
                                    setBc("")
                                    setCw("")
                                    setCurrency("")
                                    setRegion("")
                                }
                            }).catch((err)=>{
                                setLoad(false)
                                console.log(err);
                            })
                        }
                    }}>{load? "Loading" : "Update Nation"}</button>
                </div>
                </>}
            </div>
        </div>
    )
}

function User({ item, setSelect, answer}) {
    return (
        <div className='NationCon2' onClick={() => setSelect(item?.id)}>
            <div className='UserConImg'>
                <h2>{item?.name || '--'}</h2>
            </div>
            <h2>{item?.status || '--'}</h2>
            <h3 style={{color:answer?.anual?.directorSignature? '#2dcb72' : answer? '#ffc82d' : '#ff6c6c'}}>{answer?.anual?.chairSignature? "BC Signed" : answer?.anual?.directorSignature? 'ND Signed' : answer? 'Filling' : 'Not Started'}</h3>
            <h3>{answer?.anual?.date_submitted? moment(answer?.anual?.date_submitted * 1).format("LL"): "--"}</h3>
            <h3>{answer?.anual?.paying_how}</h3>
        </div>
    )
}

function CreateNation({setOpen, open}){
    const [name, setName] = useState("")
  
    const [onCreate, {loading}] = useMutation(CREATE_NATION,{
      refetchQueries:[
        {query:GET_NATION}
      ]
    })
  
    return(
      <div className='UserModalCon'>
      <div className='UserModalHead'>
        Create Nation
      </div>
      <RiCloseLine className='UserModalClose' size={18} onClick={()=>setOpen(false)}/>
        <div className='UserModalCon1' style={{marginRight:15}}>
          <div>
            <h2>Nation Name</h2>
            <input style={{width:200}} value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter Nation Name'/>
          </div>
        </div>
        <div className='UserModalCon4'>
          <div className='FlexCon'/>
          <button onClick={()=>{
            onCreate({
              variables:{
                "nation": {
                  "name": name,
                }
              }
            }).then(()=>{
                setName("")
                setOpen(false)
            }).catch((err)=>{
                alert(err?.message)
            })
          }}>{loading? "Loading" : "Create Nation"}</button>
        </div>
      </div>
    )
  }