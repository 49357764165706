import { gql } from "@apollo/client";

export const ON_LOGIN = gql`
mutation OnLogin($login: LoginInput) {
  onAdminLogin(login: $login)
}`

export const NATION_UPDATE = gql`
mutation UpdateNation($updateNationId: String, $nation: NationInput) {
  updateNation(id: $updateNationId, nation: $nation)
}`

export const AREA_UPDATE = gql`
mutation UpdateArea($updateAreaId: String, $area: AreaInput) {
  updateArea(id: $updateAreaId, area: $area)
}`

export const REGION_UPDATE = gql`
mutation UpdateRegion($updateRegionId: String, $region: RegionInput) {
  updateRegion(id: $updateRegionId, region: $region)
}`

export const CREATE_USER = gql`
mutation CreateUser($user: UserInput) {
  createUser(user: $user)
}`

export const UPDATE_USER = gql`
mutation UpdateUser($user: UserInput) {
  updateUser(user: $user)
}`

export const DELETE_USER = gql`
mutation DeleteUser($deleteUserId: String) {
  deleteUser(id: $deleteUserId)
}`

export const CREATE_NATION = gql`
mutation CreateNation($nation: NationInput) {
  createNation(nation: $nation)
}`

export const CREATE_ADMIN = gql`
mutation CreateAdmin($user: AdminInput) {
  createAdmin(user: $user)
}`

export const UPDATE_ADMIN = gql`
mutation UpdateAdmin($user: AdminInput) {
  updateAdmin(user: $user)
}`

export const DELETE_ADMIN = gql`
mutation DeleteAdmin($deleteAdminId: String) {
  deleteAdmin(id: $deleteAdminId)
}`

export const SEND_MAIL = gql`
mutation SendMail($sendMailId: String, $type: String) {
  sendMail(id: $sendMailId, type: $type)
}`

export const UPDATE_ANUALS = gql`
mutation UpdateAnuals($year: String, $anual: AnualInput) {
  updateAnualPayment(year: $year, anual: $anual)
}`

export const UPDATE_EMAIL = gql`
mutation UpdateEmail($email: EmailInput) {
  updateEmail(email: $email)
}`

export const CREATE_SCHEDULE = gql`
mutation ScheduleMail($scheduleMailId: String, $delay: Int, $mails: [String], $title: String, $date: String) {
  scheduleMail(id: $scheduleMailId, delay: $delay, mails: $mails, title: $title, date: $date)
}`

export const CREATE_LOG = gql`
mutation CreateLog($log: LogInput) {
  createLog(log: $log)
}`