import React from 'react'
import "./style.css"
import { RiGlobalFill, RiGroup2Fill, RiGroupFill, RiMailFill, RiMap2Fill, RiMapPin2Fill } from "react-icons/ri";
import { AiFillDashboard } from "react-icons/ai";
import { Link, useLocation } from 'react-router-dom'

export default function Sidebar({user}) {
  const location = useLocation()
  if(location.pathname === '/login'){
    return null
  }
  return (
    <div className='Sidebar'>
      <div className='SideLogo'>
        <h1>Chartering</h1>
      </div>
      <h3>Menu</h3>
      <Link to={'/'} className={location?.pathname === '/'? 'SidebarMenu SidebarMenuSelect':'SidebarMenu'}>
        <AiFillDashboard size={20}/>
        Dashboard
      </Link>
      {/* <Link to={'/users'} className={location?.pathname === '/users'? 'SidebarMenu SidebarMenuSelect':'SidebarMenu'}>
        <RiGroupFill size={20}/>
        Users
      </Link> */}
      <Link to={'/user'} className={location?.pathname === '/user'? 'SidebarMenu SidebarMenuSelect':'SidebarMenu'}>
        <RiGroupFill size={20}/>
        Users
      </Link>
      {/* <Link to={'/nations'} className={location?.pathname === '/nations'? 'SidebarMenu SidebarMenuSelect':'SidebarMenu'}>
        <RiGlobalFill size={20}/>
        Nations
      </Link> */}
      <Link to={'/nation'} className={location?.pathname === '/nation'? 'SidebarMenu SidebarMenuSelect':'SidebarMenu'}>
        <RiMapPin2Fill size={20}/>
        Nations
      </Link>
      <Link to={'/regions'} className={location?.pathname === '/regions'? 'SidebarMenu SidebarMenuSelect':'SidebarMenu'}>
        <RiGlobalFill size={20}/>
        Regions
      </Link>
      <Link to={'/areas'} className={location?.pathname === '/areas'? 'SidebarMenu SidebarMenuSelect':'SidebarMenu'}>
        <RiMap2Fill size={20}/>
        Areas
      </Link>
      <Link to={'/templates'} className={location?.pathname === '/templates'? 'SidebarMenu SidebarMenuSelect':'SidebarMenu'}>
        <RiMailFill size={20}/>
        Emails
      </Link>
      {user?.role === 'super admin'?
      <Link to={'/admin'} className={location?.pathname === '/admin'? 'SidebarMenu SidebarMenuSelect':'SidebarMenu'}>
        <RiGroup2Fill size={20}/>
        Admins
      </Link>
      : null}
    </div>
  )
}
