import React, { useEffect, useState } from 'react'
import "./style.css"
import { RiCloseLine, RiEdit2Line, RiFilter2Line } from "react-icons/ri";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_AREAS, GET_USERS } from '../graphql/query';
import { AREA_UPDATE } from '../graphql/mutation';

export default function Areas({user}) {
    const [selectId, setSelect] = useState(null)
    const [edit, setEdit] = useState(false)
    const { data, loading } = useQuery(GET_AREAS)

    const select = data?.getAreas?.filter((e)=>e?.id === selectId)[0]

    if (loading) {
        return (
            <div className='Loading'>
                Loading...
            </div>
        )
    }
    return (
        <>
            <div className='Home'>
                <div className='HomeHead'>
                    AREAS
                    <div className='FlexCon' />
                </div>
                <div className='UserDiv'>
                    <div className='NationDivLeft'>
                        {/* <div className='UserSearchCon'>
                            <input placeholder='Search nation' />
                            <div className='FlexCon' />
                            <button><RiFilter2Line />Filter</button>
                        </div> */}
                        <div className='UserCon'>
                            <div className='UserHead'>
                                All Areas
                                <h4>{data?.getAreas?.length}</h4>
                                <div className='FlexCon' />
                                {/* <button onClick={()=>setOpen(true)}><RiAddCircleLine/> Create User</button> */}
                            </div>
                            <div className='NationHead2'>
                                <h2>Name</h2>
                                <h2>AD name</h2>
                            </div>
                            {data?.getAreas?.map((item, index) => {
                                return (
                                    <User key={index} item={item} setSelect={setSelect} />
                                )
                            })}
                        </div>
                    </div>
                    <div className='UserDivRight'>
                        {select ?
                            <>
                                <div className='UserDivRightProfile'>
                                    <h2>{select?.name}</h2>
                                    <div className='FlexCon' />
                                    {user?.role === 'super admin'?
                                    <div className='UserDivRightEdit' onClick={() => setEdit(select)}>
                                        <RiEdit2Line />
                                    </div>
                                    : null}
                                </div>
                                <div className='NationDivRightDiv'>
                                    <h4>AD name:</h4>
                                    <h5>{select?.ad?.name || '--'}</h5>
                                </div>
                            </> : null}
                    </div>
                </div>
            </div>
            {edit ? <Report setOpen={setEdit} open={edit} /> : null}
        </>
    )
}

function Report({ setOpen, open }) {
    const [name, setName] = useState("")
    const [nd, setNd] = useState("")
    const [getData, { loading, data }] = useLazyQuery(GET_USERS)

    const [onUpdate] = useMutation(AREA_UPDATE,{
        refetchQueries:[
            {query:GET_AREAS}
        ]
    })
    const [load, setLoad] = useState(false)

    useEffect(()=>{
        if(open){
            setName(open?.name)
            setNd(open?.ad?.id)
            getData()
        }
    },[open])

    return (
        <div className={'UserModal UserModalOpen'}>
            <div className='UserModalCon' style={{minWidth:300}}>
                <div className='UserModalHead'>
                    Edit Area
                </div>
                <RiCloseLine className='UserModalClose' size={18} onClick={() => setOpen(false)} />
                {loading?
                <p style={{margin:10}}>Loading</p>
                :
                <>
                <div className='UserModalCon1'>
                    <div>
                        <h2>Area Name</h2>
                        <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Country name' />
                    </div>
                    <div className='UserModalInput'>
                    <div>
                        <h2>Select AD</h2>
                        <select value={nd} onChange={(e)=>setNd(e?.target?.value)}>
                            <option>Select AD</option>
                            {data?.getUsers?.slice()?.sort((a,b)=>{
                                if ( a.name < b.name ){
                                    return -1;
                                }
                                if ( a.name > b.name ){
                                    return 1;
                                }
                                return 0;
                                })?.map((item, index)=>{
                                return <option key={index} value={item?.id}>{item?.name}</option>
                            })}
                        </select>
                    </div>
                    </div>
                </div>
                <div className='UserModalCon1'>
                    <div className='FlexCon'/>
                    <button onClick={()=>{
                        setLoad(true)
                        onUpdate({
                        variables:{
                            "updateAreaId": open?.id,
                            "area": {
                                "ad": nd || "",
                                "name": name || ""
                            }
                        }
                        }).then(()=>{
                            setLoad(false)
                            setOpen(false)
                            setName("")
                            setNd("")
                        }).catch((err)=>{
                            setLoad(false)
                            alert(err?.message)
                        })
                    }}>{load? "Loading" : "Update Area"}</button>
                </div>
                </>}
            </div>
        </div>
    )
}

function User({ item, setSelect}) {
    return (
        <div className='NationCon2' onClick={() => setSelect(item?.id)}>
            <div className='UserConImg'>
                <h2>{item?.name || '--'}</h2>
            </div>
            <h3>{item?.ad?.name || '--'}</h3>
        </div>
    )
}