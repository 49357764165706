import React, { useEffect, useState } from 'react'
import "./style.css"
import { RiArrowDownLine, RiArrowUpLine } from 'react-icons/ri'
import Chart from "react-apexcharts";
import { useQuery } from '@apollo/client';
import { GET_CHARTER, GET_QUESTION } from '../graphql/query';
import { Link, useNavigate } from 'react-router-dom';
import World from '../assets/world';

export default function Home({ }) {
  const navigation = useNavigate()
  const [type, setType] = useState("Global")
  const [type2, setType2] = useState("")
  const [region, setRegion] = useState("")
  const [area, setArea] = useState("")
  const [nation, setNation] = useState("")
  const [year, setYear] = useState('2022')


  const GET_QUERY = year === '2022' ? GET_CHARTER : GET_QUESTION

  const { data, loading } = useQuery(GET_QUERY, {
    variables: {
      year: year
    }
  })

  const getNationalIncome = ({ data, what, region, area, nation, graph, abb }) => {
    if (year === '2022') {
      let total = 0;
      if (nation) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].user?.country === nation && data[i][what] !== null) {
            total += parseInt(data[i][what] * data[i].user?.exchange);
          }
        }
      } else if (region) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].user?.region === region && data[i][what] !== null) {
            total += parseInt(data[i][what] * data[i].user?.exchange);
          }
        }
      } else if (area) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].user?.area === area && data[i][what] !== null) {
            total += parseInt(data[i][what] * data[i].user?.exchange);
          } else if (area === "Others" && data[i].user?.area === null && data[i][what] !== null) {
            total += parseInt(data[i][what] * data[i].user?.exchange);
          }
        }
      } else {
        for (let i = 0; i < data?.length; i++) {
          if (data[i][what] !== null && data[i].user?.country) {
            total += parseInt(data[i][what] * data[i].user?.exchange);
          }
        }
      }
      if (graph) {
        return total
      } else {
        return abb ? total : abbreviateNumber(total);
      }
    } else {
      let total = 0;
      if (nation) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].nation?.name === nation && data[i][what] !== null) {
            total += parseInt(data[i][what] * (data[i].nation?.exchange || 1));
          }
        }
      } else if (region) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].nation?.region?.name === region && data[i][what] !== null) {
            total += parseInt(data[i][what] * (data[i].nation?.exchange || 1));
          }
        }
      } else if (area) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].nation?.area?.name === area && data[i][what] !== null) {
            total += parseInt(data[i][what] * (data[i].nation?.exchange || 1));
          } else if (area === "Others" && data[i].nation?.area?.name === null && data[i][what] !== null) {
            total += parseInt(data[i][what] * (data[i].nation?.exchange || 1));
          }
        }
      } else {
        for (let i = 0; i < data?.length; i++) {
          if (data[i][what] !== null && data[i].nation?.name) {
            total += parseInt(data[i][what] * (data[i].nation?.exchange || 1));
          }
        }
      }
      if (graph) {
        return total
      } else {
        return abb ? total : abbreviateNumber(total);
      }
    }
  };

  const getOthers = ({ data, what, region, area, nation, graph, abb }) => {
    if (year === '2022') {
      let total = 0;
      if (nation) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].user?.country === nation && data[i][what]) {
            total = parseInt(data[i][what]);
          }
        }
      } else if (region) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].user?.region === region && data[i][what]) {
            total += parseInt(data[i][what]);
          }
        }
      } else if (area) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].user?.area === area && data[i][what]) {
            if (data[i][what])
              total += parseInt(data[i][what]);
          } else if (area === "Others" && data[i].user?.area === null && data[i][what]) {
            total += parseInt(data[i][what]);
          }
        }
      } else {
        for (let i = 0; i < data?.length; i++) {
          if (data[i][what] && data[i].user?.country) {
            total += parseInt(data[i][what]);
          }
        }
      }
      if (graph) {
        return total
      } else {
        return abb ? total : abbreviateNumber(total);
      }
    } else {
      let total = 0;
      if (nation) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].nation?.name === nation && data[i][what]) {
            total = parseInt(data[i][what]);
          }
        }
      } else if (region) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].nation?.region?.name === region && data[i][what]) {
            total += parseInt(data[i][what]);
          }
        }
      } else if (area) {
        for (let i = 0; i < data?.length; i++) {
          if (data[i].nation?.area?.name === area && data[i][what]) {
            if (data[i][what])
              total += parseInt(data[i][what]);
          } else if (area === "Others" && data[i].nation?.area?.name === null && data[i][what]) {
            total += parseInt(data[i][what]);
          }
        }
      } else {
        for (let i = 0; i < data?.length; i++) {
          if (data[i][what] && data[i].nation?.name) {
            total += parseInt(data[i][what]);
          }
        }
      }
      if (graph) {
        return total
      } else {
        return abb ? total : abbreviateNumber(total);
      }
    }
  };

  function abbreviateNumber(number) {
    if (number >= 1000000 || number <= -1000000) {
      return (number / 1000000).toFixed(1) + 'M';
    }
    if (number >= 1000 || number <= -1000) {
      return (number / 1000).toFixed(1) + 'K';
    }
    return number.toString();
  }

  function getSocialMedia(val) {
    const filteredOuterArray = type2 === 'Nation' || type === 'Nation' ? data?.getCharters?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem === val) && item?.user?.country === nation
    }) : type2 === 'Region' || type === 'Region' ? data?.getCharters?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem === val) && item?.user?.region === region
    }) : type2 === 'Area' || type === 'Area' ? data?.getCharters?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem === val) && item?.user?.area === area
    }) : data?.getCharters?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem === val)
    })
    const filteredOthers = type2 === 'Region' || type === 'Region' ? data?.getCharters?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem?.length > 0 && innerItem !== "1" && innerItem !== "2" && innerItem !== "3" && innerItem !== "4" && innerItem !== "5" && innerItem !== "6" && innerItem !== "7") && item?.user?.region === region
    }) : type2 === 'Area' || type === 'Area' ? data?.getCharters?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem?.length > 0 && innerItem !== "1" && innerItem !== "2" && innerItem !== "3" && innerItem !== "4" && innerItem !== "5" && innerItem !== "6" && innerItem !== "7") && item?.user?.area === area
    }) : data?.getCharters?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem?.length > 0 && innerItem !== "1" && innerItem !== "2" && innerItem !== "3" && innerItem !== "4" && innerItem !== "5" && innerItem !== "6" && innerItem !== "7")
    })

    const filteredOuterArray1 = type2 === 'Nation' || type === 'Nation' ? data?.getQuestions?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem === val) && item?.nation?.name === nation
    }) : type2 === 'Region' || type === 'Region' ? data?.getQuestions?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem === val) && item?.nation?.region?.name === region
    }) : type2 === 'Area' || type === 'Area' ? data?.getQuestions?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem === val) && item?.nation?.area?.name === area
    }) : data?.getQuestions?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem === val)
    })
    const filteredOthers1 = type2 === 'Region' || type === 'Region' ? data?.getQuestions?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem?.length > 0 && innerItem !== "1" && innerItem !== "2" && innerItem !== "3" && innerItem !== "4" && innerItem !== "5" && innerItem !== "6" && innerItem !== "7") && item?.nation?.region?.name === region
    }) : type2 === 'Area' || type === 'Area' ? data?.getQuestions?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem?.length > 0 && innerItem !== "1" && innerItem !== "2" && innerItem !== "3" && innerItem !== "4" && innerItem !== "5" && innerItem !== "6" && innerItem !== "7") && item?.nation?.area?.name === area
    }) : data?.getQuestions?.filter(item => {
      return item?.socialPlatform?.some(innerItem => innerItem?.length > 0 && innerItem !== "1" && innerItem !== "2" && innerItem !== "3" && innerItem !== "4" && innerItem !== "5" && innerItem !== "6" && innerItem !== "7")
    })

    if (year === '2022') {
      return val === "Others" ? filteredOthers?.length : filteredOuterArray?.length
    } else {
      return val === "Others" ? filteredOthers1?.length : filteredOuterArray1?.length
    }
  }

  const GetAreas = () => {
    let val = []
    if (year === '2022') {
      data?.getCharters?.filter((e) => e?.user?.area === area)?.forEach((item) => {
        if (val?.filter((e) => e === item?.user?.region)?.length === 0 && item?.user?.region) {
          val.push(item?.user?.region)
        }
      })
    } else {
      data?.getQuestions?.filter((e) => e?.nation?.area?.name === area)?.forEach((item) => {
        if (val?.filter((e) => e === item?.nation?.region?.name)?.length === 0 && item?.nation?.region?.name) {
          val.push(item?.nation?.region?.name)
        }
      })
    }
    return val
  }

  const GetRegions = () => {
    let val = []
    if (year === '2022') {
      data?.getCharters?.filter((e) => e?.user?.region === region)?.forEach((item) => {
        if (val?.filter((e) => e === item?.user?.country)?.length === 0 && item?.user?.country) {
          val.push(item?.user?.country)
        }
      })
    } else {
      data?.getQuestions?.filter((e) => e?.nation?.region?.name === region)?.forEach((item) => {
        if (val?.filter((e) => e === item?.nation?.name)?.length === 0 && item?.nation?.name) {
          val.push(item?.nation?.name)
        }
      })
    }
    return val
  }

  function countNumbers(arrays) {
    let countOfOnes = 0;
    let countOfTwos = 0;
    let countOfThrees = 0;
    let countOfFours = 0;
    for (let i = 0; i < arrays?.length; i++) {
      for (let j = 0; j < arrays[i].length; j++) {
        if (arrays[i][j] === '1') {
          countOfOnes++;
        } else if (arrays[i][j] === '2') {
          countOfTwos++;
        } else if (arrays[i][j] === '3') {
          countOfThrees++;
        } else if (arrays[i][j] === '4') {
          countOfFours++;
        }
      }
    }
    return {
      "one": countOfOnes,
      "two": countOfTwos,
      "three": countOfThrees,
      "four": countOfFours
    }
  }

  const areaDatas = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'line',
        zoom: {
          enabled: false
        },
        dropShadow: {
          enabled: true,
          color: '#5078ed',
          top: 0,
          left: 0,
          blur: 1,
          opacity: 0.2
        },
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      colors: ["#000", "#53d285"],
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: type2 === 'Region' || type === 'Region' ? GetRegions() : type2 === 'Area' || type === 'Area' ? GetAreas() : ["Africa", "America", "EMENA", "Asia Pacific", "Others"]
      }
    },
  }

  const areaDatas1 = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'line',
        zoom: {
          enabled: false
        },
        dropShadow: {
          enabled: true,
          color: '#5078ed',
          top: 0,
          left: 0,
          blur: 1,
          opacity: 0.2
        },
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      colors: ["#000", "#53d285"],
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: type2 === 'Region' || type === 'Region' ? GetRegions() : type2 === 'Area' || type === 'Area' ? GetAreas() : ["Africa", "America", "EMENA", "Asia Pacific", "Others"]
      }
    },
  }

  const areaDatas2 = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'line',
        zoom: {
          enabled: false
        },
        dropShadow: {
          enabled: true,
          color: '#5078ed',
          top: 0,
          left: 0,
          blur: 1,
          opacity: 0.2
        },
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      colors: ["#000", "#53d285"],
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: type2 === 'Region' || type === 'Region' ? GetRegions() : type2 === 'Area' || type === 'Area' ? GetAreas() : ["Africa", "America", "EMENA", "Asia Pacific", "Others"]
      }
    },
  }

  const data3 = {
    series: [{
      name: "Desktops",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    }],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        categories: type2 === 'Region' || type === 'Region' ? GetRegions() : type2 === 'Area' || type === 'Area' ? GetAreas() : ["Africa", "America", "EMENA", "Asia Pacific", "Others"],
      }
    },
  }

  const data4 = {
    series: [44, 55, 41, 17, 15, 10, 20, 5],
    options: {
      labels: ['DASK', 'Facebook', 'Instagram', 'YouTube', 'WhatsApp', 'Discord', 'TikTok', 'Other'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            width: 200,
            height: 500
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  }

  const data5 = {
    series: [44, 55, 41, 17, 15, 10, 20, 5],
    options: {
      labels: type2 === 'Region' || type === 'Region' ? GetRegions() : type2 === 'Area' || type === 'Area' ? GetAreas() : ["Africa", "America", "EMENA", "Asia Pacific", "Others"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            width: 200,
            height: 500
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  }

  const staffData = {
    series: [{
      name: 'Staff',
      data: type2 === "Region" || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPaidService", abb: true, nation: item }) }) : GetAreas()?.map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPaidService", abb: true, region: item }) })
    }, {
      name: 'Volunteers',
      data: type2 === "Region" || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noRegularService", abb: true, nation: item }) }) : GetAreas()?.map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noRegularService", abb: true, region: item }) })
    }],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: type2 === "Region" || type === 'Region' ? GetRegions() : GetAreas(),
      },
      yaxis: {
        title: {
          text: 'People'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    },
  }

  const youngPeople = {
    series: [{
      name: 'Direct Ministry',
      data: type2 === "Region" || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungDirectsMinistry", abb: true, nation: item }) }) : GetAreas().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungDirectsMinistry", abb: true, region: item }) })
    }, {
      name: 'Media Ministry',
      data: type2 === "Region" || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungMediasMinistry", abb: true, nation: item }) }) : GetAreas().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungMediasMinistry", abb: true, region: item }) })
    }],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: type2 === "Region" || type === 'Region' ? GetRegions() : GetAreas(),
      },
      yaxis: {
        title: {
          text: 'People'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    },
  }

  const disciple = {
    series: [{
      name: 'Discipleship',
      data: type2 === "Region" || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noDiscipleship", abb: true, nation: item }) }) : GetAreas().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noDiscipleship", abb: true, region: item }) })
    },
    {
      name: 'Staff',
      data: type2 === "Region" || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPaidService", abb: true, nation: item }) + getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noRegularService", abb: true, nation: item }) }) : GetAreas().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPaidService", abb: true, nation: item }) + getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noRegularService", abb: true, region: item }) })
    }],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: type2 === "Region" || type === 'Region' ? GetRegions() : GetAreas(),
      },
      yaxis: {
        title: {
          text: 'People'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    },
  }

  const moneyYoungPeople = {
    series: [{
      name: 'Amount Spend',
      data: type2 === "Region" || type === 'Region' ? GetRegions().map((item) => { return ((getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, nation: item }) + getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "localExpenditures", abb: true, nation: item })) / (getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungDirectsMinistry", abb: true, nation: item }) + getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungMediasMinistry", abb: true, nation: item }))).toFixed(2) }) : GetAreas().map((item) => { return ((getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, region: item }) + getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "localExpenditures", abb: true, region: item })) / (getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungDirectsMinistry", abb: true, region: item }) + getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungMediasMinistry", abb: true, region: item }))).toFixed(2) })
    },
    {
      name: 'Young People',
      data: type2 === "Region" || type === 'Region' ? GetRegions().map((item) => { return 1 }) : GetAreas().map((item) => { return 1 })
    }],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: type2 === "Region" || type === 'Region' ? GetRegions() : GetAreas(),
      },
      yaxis: {
        title: {
          text: 'Amount in USD'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    },
  }

  const churchPartner = {
    series: [{
      name: 'Partners',
      data: type2 === "Region" || type === 'Region' ? GetRegions().map((item) => { return getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noChurchPartner", abb: true, nation: item }) }) : GetAreas().map((item) => { return getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noChurchPartner", abb: true, region: item }) })
    },],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: type2 === "Region" || type === 'Region' ? GetRegions() : GetAreas(),
      },
      yaxis: {
        title: {
          text: 'Churches'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    },
  }

  const ministry = {
    series: [
      {
        data: [
          {
            x: 'Evangelism',
            y: type2 === "Region" || type === 'Region' ? countNumbers(data?.getCharters?.filter((e) => e?.user?.region === region)?.map((e) => { return e?.ministryActivity }))?.one : countNumbers(data?.getCharters?.filter((e) => e?.user?.area === area)?.map((e) => { return e?.ministryActivity }))?.one
          },
          {
            x: 'Discipleship',
            y: type2 === "Region" || type === 'Region' ? countNumbers(data?.getCharters?.filter((e) => e?.user?.region === region)?.map((e) => { return e?.ministryActivity }))?.two : countNumbers(data?.getCharters?.filter((e) => e?.user?.area === area)?.map((e) => { return e?.ministryActivity }))?.two
          },
          {
            x: 'Prayer',
            y: type2 === "Region" || type === 'Region' ? countNumbers(data?.getCharters?.filter((e) => e?.user?.region === region)?.map((e) => { return e?.ministryActivity }))?.three : countNumbers(data?.getCharters?.filter((e) => e?.user?.area === area)?.map((e) => { return e?.ministryActivity }))?.three
          },
          {
            x: 'Social Involvement',
            y: type2 === "Region" || type === 'Region' ? countNumbers(data?.getCharters?.filter((e) => e?.user?.region === region)?.map((e) => { return e?.ministryActivity }))?.four : countNumbers(data?.getCharters?.filter((e) => e?.user?.area === area)?.map((e) => { return e?.ministryActivity }))?.four
          }
        ]
      }
    ],
    options: {
      legend: {
        show: false
      },
      chart: {
        toolbar: {
          show: false,
        },
        height: 350,
        type: 'treemap'
      },
    },
  }

  const preOwn = {
    series: [{
      name: "Own Nation",
      data: type2 === 'Region' || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, nation: item }) }) :
        type2 === 'Area' || type === 'Area' ? GetAreas().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, region: item }) }) : [
          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, area: "Africa" }),
          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, area: "Americas" }),
          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, area: "EMENA" }),
          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, area: "Asia Pacific" }),
          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, area: "Others" }),
        ]
    }, {
      name: "Outside Nation",
      data: type2 === 'Region' || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, nation: item }) }) :
        type2 === 'Area' || type === 'Area' ? GetAreas().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, region: item }) }) : [
          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, area: "Africa" }),
          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, area: "Americas" }),
          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, area: "EMENA" }),
          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, area: "Asia Pacific" }),
          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "preRaisedOwn", abb: true, area: "Others" }),
        ]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 430
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: type2 === 'Region' || type === 'Region' ? GetRegions() : type2 === 'Area' || type === 'Area' ? GetAreas() : ["Africa", "America", "EMENA", "Asia Pacific", "Others"],
      },
    },
  }

  const allAreas = [
    { id: "2ee1ac5d-3f30-42f7-8d1f-7108b86fea4c", name: "Asia Pacific" },
    { id: "9e202c3b-28b2-47fe-adad-b8e3e9bed5a7", name: "Americas" },
    { id: "7a366b0c-46ea-4f38-b206-299b3ec125b3", name: "Africa" },
    { id: "412a5efd-b2f0-4990-bfac-cfb488a76f83", name: "EMENA" },
  ]

  const years = ["2004", "2005", "2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"]

  if (loading) {
    return (
      <div className='Loading'>
        Loading...
      </div>
    )
  }
  return (
    <div className='Home'>
      <div className='HomeHead'>
        ANALYTICS
        <div className='FlexCon' />
        {type === 'Global' ?
          <>
            <select value={area} onChange={(e) => {
              setType2("Area")
              setRegion("")
              setNation("")
              setArea(e?.target?.value)
            }
            }>
              <option hidden>Select Area</option>
              {allAreas?.map((item, index) => {
                return (
                  <option key={index}>{item?.name}</option>
                )
              })}
            </select>
            {area ?
              <select value={region} onChange={(e) => {
                setType2("Region")
                setNation("")
                setRegion(e?.target?.value)
              }}>
                <option hidden>Select Region</option>
                {GetAreas()?.map((item, index) => {
                  return <option key={index}>{item}</option>
                })}
              </select>
              : null}
            {region ?
              <select value={nation} onChange={(e) => {
                setType2("Nation")
                setNation(e?.target?.value)
              }}>
                <option hidden>Select Nation</option>
                {GetRegions()?.map((item, index) => {
                  return <option key={index}>{item}</option>
                })}
              </select>
              : null}
          </>
          : null}
        <select value={year} onChange={(e) => setYear(e.target.value)}>
          {years?.map((item, index) => {
            return <option key={index} value={item}>{item}</option>
          })}
        </select>
      </div>
      <div className='HomeStatCon'>
        {type === 'Nation' ?
          <div className='HomeStat'>
            <h3>Year</h3>
            <h2>{year}</h2>
          </div>
          : null}
        <div className='HomeStat'>
          <h3>{type2 === 'Nation' || type === 'Nation' ? "Name of Nation" : "No of Nations"}</h3>
          {year === '2022' ?
            <h2>{type2 === 'Area' || type === 'Area' ? data?.getCharters?.filter((e) => e?.user?.area === area)?.length : type2 === 'Region' || type === 'Region' ? data?.getCharters?.filter((e) => e?.user?.region === region)?.length : type2 === 'Nation' || type === 'Nation' ? nation : data?.getCharters?.filter((e) => e?.country !== '')?.length}</h2>
            :
            <h2>{type2 === 'Area' || type === 'Area' ? data?.getNations?.filter((e) => e?.area?.name === area)?.length : type2 === 'Region' || type === 'Region' ? data?.getNations?.filter((e) => e?.region?.name === region)?.length : type2 === 'Nation' || type === 'Nation' ? nation : data?.getNations?.length}</h2>
          }
          {/* <h4 style={{ paddingLeft: 7 }}>40% filled</h4> */}
        </div>
        <div className='HomeStat'>
          <h3 onClick={() => console.log(data?.getCharters?.map((itm) => {
            return itm?.preRaisedOwn
          }))}>{type2 || type} Income (USD)</h3>
          <h2>${getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", area: area, region: region, nation: nation })}</h2>
          <div className='HomeStatDiv'>
            {/* <h4><RiArrowUpLine className='HomeStatIcon' />13%</h4> */}
            {/* <h3 style={{ marginLeft: 0 }}>vs last year</h3> */}
          </div>
        </div>
        <div className='HomeStat'>
          <h3>{type2 || type} Expense (USD)</h3>
          <h2>${getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", area: area, region: region, nation: nation })}</h2>
          <div className='HomeStatDiv'>
            {/* <h5><RiArrowDownLine className='HomeStatIcon' />21%</h5> */}
            {/* <h3 style={{ marginLeft: 0 }}>vs last year</h3> */}
          </div>
        </div>
        <div className='HomeStat'>
          <h3>{type2 || type} Net (USD)</h3>
          <h2>${abbreviateNumber(getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: area, region: region, nation: nation }) - getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: area, region: region, nation: nation }))}</h2>
          <div className='HomeStatDiv'>
            {/* <h4><RiArrowUpLine className='HomeStatIcon' />27%</h4> */}
            {/* <h3 style={{ marginLeft: 0 }}>vs last year</h3> */}
          </div>
        </div>
        {type2 === 'Nation' || type === 'Nation' ?
          <>
            <div className='HomeStat'>
              <h3>Prayer Intercessors</h3>
              {year === '2022' ?
                <h2>{data?.getCharters?.find((e) => e?.user?.country === nation)?.noPrayerIntercessors || '--'}</h2>
                :
                <h2>{data?.getQuestions?.find((e) => e?.nation?.name === nation)?.noPrayerIntercessors || '--'}</h2>
              }
            </div>
            <div className='HomeStat'>
              <h3>Young Leaders</h3>
              {year === '2022' ?
                <h2>{data?.getCharters?.find((e) => e?.user?.country === nation)?.noYoungLeaders || '--'}</h2>
                :
                <h2>{data?.getQuestions?.find((e) => e?.nation?.name === nation)?.noYoungLeaders || '--'}</h2>
              }
            </div>
            <div className='HomeStat'>
              <h3>Social Platform</h3>
              {year === '2022' ? data?.getCharters?.find((e) => e?.user?.country === nation)?.socialPlatform?.map((item, index) => { return <h2 key={index}>{socials[item]}</h2> })
                :
                data?.getQuestions?.find((e) => e?.nation?.name === nation)?.socialPlatform?.map((item, index) => { return <h2 key={index}>{socials[item]}</h2> }) || <h2>--</h2>}
            </div>
            <div className='HomeStat'>
              <h3>Staff volunteer ratio</h3>
              {year === '2022' ?
                <h2>{data?.getCharters?.find((e) => e?.user?.country === nation)?.noPaidService || '--'} : {data?.getCharters?.find((e) => e?.user?.country === nation)?.noDiscipleship || '--'}</h2>
                :
                <h2>{data?.getQuestions?.find((e) => e?.nation?.name === nation)?.noPaidService || '--'} : {data?.getQuestions?.find((e) => e?.nation?.name === nation)?.noDiscipleship || '--'}</h2>
              }
            </div>
            <div className='HomeStat'>
              <h3>Young people reached (Direct : Media)</h3>
              {year === '2022' ?
                <h2>{data?.getCharters?.find((e) => e?.user?.country === nation)?.noYoungDirectsMinistry || '--'} : {data?.getCharters?.find((e) => e?.user?.country === nation)?.noYoungMediasMinistry || '--'}</h2>
                :
                <h2>{data?.getQuestions?.find((e) => e?.nation?.name === nation)?.noYoungDirectsMinistry || '--'} : {data?.getQuestions?.find((e) => e?.nation?.name === nation)?.noYoungMediasMinistry || '--'}</h2>
              }
            </div>
            <div className='HomeStat'>
              <h3>Discipleship (Discipleship : Paid volunteers)</h3>
              {year === '2022' ?
                <h2>{data?.getCharters?.find((e) => e?.user?.country === nation)?.noDiscipleship || '--'} : {data?.getCharters?.find((e) => e?.user?.country === nation)?.noPaidService || '--'}</h2>
                :
                <h2>{data?.getQuestions?.find((e) => e?.nation?.name === nation)?.noDiscipleship || '--'} : {data?.getQuestions?.find((e) => e?.nation?.name === nation)?.noPaidService || '--'}</h2>
              }
            </div>
            <div className='HomeStat'>
              <h3>Money spend on reaching one young person</h3>
              {year === '2022' ?
                <h2>${((data?.getCharters?.find((e) => e?.user?.country === nation)?.nationalExpenditures + data?.getCharters?.find((e) => e?.user?.country === nation)?.localExpenditures) / (data?.getCharters?.find((e) => e?.user?.country === nation)?.noYoungDirectsMinistry + data?.getCharters?.find((e) => e?.user?.country === nation)?.noYoungMediasMinistry))?.toFixed(2) || '--'}</h2>
                :
                <h2>${((data?.getQuestions?.find((e) => e?.nation?.name === nation)?.nationalExpenditures + data?.getQuestions?.find((e) => e?.nation?.name === nation)?.localExpenditures) / (data?.getQuestions?.find((e) => e?.nation?.name === nation)?.noYoungDirectsMinistry + data?.getQuestions?.find((e) => e?.nation?.name === nation)?.noYoungMediasMinistry))?.toFixed(2) || '--'}</h2>
              }
            </div>
          </>
          : null}
      </div>
      {type2 === 'Nation' || type === 'Nation' ?
        null
        :
        <>
          <div className='HomeChart'>
            <div className='HomeChart1'>
              <h3>{type2 || type} Income (USD)</h3>
              <Chart
                options={areaDatas.options}
                series={[
                  {
                    name: year,
                    data: type2 === "Region" || type === 'Region' ? GetRegions().map((item) => { return getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, nation: item }) }) :
                      type2 === 'Area' || type === 'Area' ? GetAreas().map((item) => { return getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, region: item }) }) : [getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: "Africa" }), getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: "Americas" }), getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: "EMENA" }), getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: "Asia Pacific" }), getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: "Others" })]
                  },
                ]}
                type="line"
                height={320}
              />
            </div>
            <div className='HomeChart1'>
              <h3>{type2 || type} Expense (USD)</h3>
              <Chart
                options={areaDatas1.options}
                series={[
                  {
                    name: year,
                    data: type2 === 'Region' || type === 'Region' ? GetRegions().map((item) => { return getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, nation: item }) }) :
                      type2 === 'Area' || type === 'Area' ? GetAreas().map((item) => { return getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, region: item }) })
                        : [getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: "Africa" }), getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: "Americas" }), getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: "EMENA" }), getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: "Asia Pacific" }), getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: "Others" })]
                  }
                ]}
                type="line"
                height={320}
              />
            </div>
            <div className='HomeChart1'>
              <h3>{type2 || type} Net (USD)</h3>
              <Chart
                options={areaDatas2.options}
                series={[
                  {
                    name: year,
                    data: type2 === 'Region' || type === 'Region' ? GetRegions().map((item) => { return getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, nation: item }) - getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, nation: item }) }) :
                      type2 === 'Area' || type === 'Area' ? GetAreas().map((item) => { return getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, region: item }) - getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, region: item }) }) : [
                        getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: "Africa" }) - getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: "Africa" }),
                        getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: "Americas" }) - getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: "Americas" }),
                        getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: "EMENA" }) - getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: "EMENA" }),
                        getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: "Asia Pacific" }) - getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: "Asia Pacific" }),
                        getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalIncome", abb: true, area: "Others" }) - getNationalIncome({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "nationalExpenditures", abb: true, area: "Others" }),
                      ]
                  }
                ]}
                type="line"
                height={320}
              />
            </div>
          </div>
          <div className='HomeChart'>
            <div className='HomeChart2'>
              <h3>Young Leaders</h3>
              <Chart
                type='bar'
                series={
                  [
                    {
                      name: year,
                      data: type2 === 'Region' || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungLeaders", abb: true, nation: item }) }) :
                        type2 === 'Area' || type === 'Area' ? GetAreas().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungLeaders", abb: true, region: item }) }) : [
                          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungLeaders", abb: true, area: "Africa" }),
                          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungLeaders", abb: true, area: "Americas" }),
                          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungLeaders", abb: true, area: "EMENA" }),
                          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungLeaders", abb: true, area: "Asia Pacific" }),
                          getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungLeaders", abb: true, area: "Others" }),
                        ]
                    }
                  ]
                }
                options={data3?.options}
                width={"100%"}
              />
            </div>
            <div className='HomeChart2'>
              <h3>Social Media</h3>
              <Chart
                type='donut'
                series={[getSocialMedia("1"), getSocialMedia("2"), getSocialMedia("3"), getSocialMedia("4"), getSocialMedia("5"), getSocialMedia("6"), getSocialMedia("7"), getSocialMedia("Others")]}
                options={data4?.options}
                width={"100%"}
              />
            </div>
            <div className='HomeChart2'>
              <h3>Prayer Intercessors</h3>
              <Chart
                type='pie'
                series={type2 === 'Region' || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPrayerIntercessors", abb: true, nation: item }) }) : type2 === 'Area' || type === 'Area' ? GetAreas().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPrayerIntercessors", abb: true, region: item }) }) : [getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPrayerIntercessors", abb: true, area: "Africa" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPrayerIntercessors", abb: true, area: "Americas" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPrayerIntercessors", abb: true, area: "EMENA" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPrayerIntercessors", abb: true, area: "Asia Pacific" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPrayerIntercessors", abb: true, area: "Others" })]}
                options={data5?.options}
                width={"100%"}
              />
            </div>
            <div className='HomeChart2'>
              <h3>Direct ministry</h3>
              <Chart
                type='donut'
                series={type2 === 'Region' || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowDirectsMinistry", abb: true, nation: item }) }) : type2 === 'Area' || type === 'Area' ? GetAreas().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowDirectsMinistry", abb: true, region: item }) }) : [getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowDirectsMinistry", abb: true, area: "Africa" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowDirectsMinistry", abb: true, area: "Americas" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowDirectsMinistry", abb: true, area: "EMENA" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowDirectsMinistry", abb: true, area: "Asia Pacific" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowDirectsMinistry", abb: true, area: "Others" })]}
                options={data5?.options}
                width={"100%"}
              />
            </div>
            <div className='HomeChart2'>
              <h3>Media ministry</h3>
              <Chart
                type='pie'
                series={type2 === 'Region' || type === 'Region' ? GetRegions().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowMediasMinistry", abb: true, nation: item }) }) : type2 === 'Area' || type === 'Area' ? GetAreas().map((item) => { return getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowMediasMinistry", abb: true, region: item }) }) : [getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowMediasMinistry", abb: true, area: "Africa" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowMediasMinistry", abb: true, area: "Americas" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowMediasMinistry", abb: true, area: "EMENA" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowMediasMinistry", abb: true, area: "Asia Pacific" }), getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowMediasMinistry", abb: true, area: "Others" })]}
                options={data5?.options}
                width={"100%"}
              />
            </div>
            <div className='HomeChart4'>
              {/* <World/> */}
              {/* <h3>Own Nation Income</h3>
              <Chart
                options={preOwn.options}
                series={preOwn.series}
                type="bar"
              /> */}
            </div>
          </div>
          {type2 === 'Region' || type === 'Region' || type2 === 'Area' || type === 'Area' ?
            <div className='HomeChart3'>
              <h3>Staff volunteer ratio</h3>
              <Chart options={staffData.options} series={staffData.series} type='bar' height={350} />
            </div>
            : null
          }
          {type2 === 'Region' || type === 'Region' || type2 === 'Area' || type === 'Area' ?
            <div className='HomeChart3'>
              <h3>Young people reached</h3>
              <Chart options={youngPeople.options} series={youngPeople.series} type='bar' height={350} />
            </div>
            : null}
          {type2 === 'Region' || type === 'Region' || type2 === 'Area' || type === 'Area' ?
            <div className='HomeChart3'>
              <h3>Discipleship</h3>
              <Chart options={disciple.options} series={disciple.series} type='bar' height={350} />
            </div>
            : null}
          {type2 === 'Region' || type === 'Region' || type2 === 'Area' || type === 'Area' ?
            <div className='HomeChart3'>
              <h3>Money spend on reaching one young person</h3>
              <Chart options={moneyYoungPeople.options} series={moneyYoungPeople.series} type='bar' height={350} />
            </div>
            : null
          }
          {type2 === 'Region' || type === 'Region' || type2 === 'Area' || type === 'Area' ?
            <div className='HomeChart3'>
              <h3>Church Partnership</h3>
              <Chart options={churchPartner.options} series={churchPartner.series} type='bar' height={350} />
            </div>
            : null
          }
          {type2 === 'Region' || type === 'Region' || type2 === 'Area' || type === 'Area' ?
            <div className='HomeChart3'>
              <h3>Ministry modal</h3>
              <div style={{ margin: '0 20px' }}>
                <Chart options={ministry.options} series={ministry.series} type="treemap" height={350} />
              </div>
            </div>
            : null}
        </>
      }
      <div className='HomeQuestCon'>
        <table>
          <tr>
            <td>Number of YFC Centres in Your Country</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYFCCenters", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of Young People Contacted by Direct Ministry</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungDirectsMinistry", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            {/* <td><h5><RiArrowDownLine className='HomeStatIcon' />21%</h5></td> */}
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of Young People Given an Opportunity to be a Follower of Jesus Christ through your Direct Ministry</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowDirectsMinistry", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of Young People Who Responded to the Opportunity to be a Follower of Jesus Christ through your Media Ministry</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noFollowMediasMinistry", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of Young People Involved in Follow-Up/Discipleship Programs through both Media Ministry and Direct Ministry</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noDiscipleship", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            {/* <td><h5><RiArrowDownLine className='HomeStatIcon' />21%</h5></td> */}
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of young leaders in a formalized Young Leader Development Process</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noYoungLeaders", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of Mission Teams</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noMissionTeam", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of participants on the teams sent</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noParticipantsTeam", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Total number of staff and volunteers serving in your nation?</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "serviceVolunteers", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Of this number, how many are paid a salary or hourly wage for their time (not only ministry expenses)?</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPaidService", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Of this number, how many are volunteers serving on a regular basis?</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noRegularService", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of staff and volunteers sent from your nation to reside and serve in another nation</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noStaffOtherNation", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>How many people served as voting members of the National Board or its equivalent?</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noBoardMember", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of Local Churches with Whom You Have Some kind of a Mutually Understood Partnership</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noChurchPartner", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of Separate Denominations You Work With in Your nation</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noDenominations", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            {/* <td><h5><RiArrowDownLine className='HomeStatIcon'/>0%</h5></td> */}
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
          <tr>
            <td>Number of Registered Prayer Intercessors Praying for FC in Your Country	</td>
            <td>{getOthers({ data: year === '2022' ? data?.getCharters : data?.getQuestions, what: "noPrayerIntercessors", abb: true, area: type2 === 'Area' || type === 'Area' ? area : null, region: type2 === 'Region' || type === 'Region' ? region : null, nation: type2 === 'Nation' || type === 'Nation' ? nation : null })}</td>
            <td><h4><RiArrowUpLine className='HomeStatIcon' />0%</h4></td>
          </tr>
        </table>
      </div>
    </div>
  )
}

const socials = ['DASK', 'Facebook', 'Instagram', 'YouTube', 'WhatsApp', 'Discord', 'TikTok', 'Other']

const regions = [
  "Southern",
  "Western",
  "Latin America South",
  "Eastern Europe",
  "Central Europe",
  "Francophone",
  "Central Asia",
  "Eastern",
  "Caribbean",
  "Balkans",
  "East Asia",
  "South Asia",
  "Southern Europe",
  "North America",
  "Southeast Asia",
  "Western Africa",
  "Pacific",
  "Latin America Central",
  "Western Europe",
  "Portuguese Speaking Region",
  "Indian Ocean",
  "Eastern Africa",
  "Aus/NZ",
  "Middle East / North Africa",
]

const nations = [
  "Cape Verde",
  "Mongolia",
  "Botswana",
  "Ireland",
  "Morocco",
  "Lebanon",
  "Austria",
  "Croatia",
  "Spain",
  "China NW*",
  "Uzbekistan*",
  "Tanzania",
  "Papua New Guinea",
  "Thailand",
  "Ecuador",
  "Nepal*",
  "South Sudan*",
  "Senegal",
  "Zambia",
  "South Africa",
  "Kosovo",
  "Egypt*",
  "Côte d’Ivoire",
  "Northern Ireland",
  "Honduras",
  "Nicaragua",
  "Bulgeria",
  "Gambia",
  "Jordan",
  "Sri Lanka*",
  "Guatemala",
  "Canada",
  "Niger",
  "Iraq*",
  "Demo",
  "North Macedonia",
  "Paraguay",
  "Korea",
  "Hungary",
  "Burundi",
  "Mozambique",
  "Trinidad & Tobago",
  "Nauru",
  "Poland",
  "Slovakia",
  "Philippines",
  "Mauritius",
  "Liberia",
  "Tonga",
  "Tajikistan*",
  "Malaysia*",
  "Tunisia",
  "Indonesia*",
  "Australia",
  "The Netherlands",
  "Guinea",
  "Armenia",
  "Eswatini",
  "Serbia",
  "Syria*",
  "Dem Republic of Congo",
  "Haiti",
  "Portugal",
  "USA",
  "Myanmar*",
  "Kyrgyzstan*",
  "Russia",
  "Kenya",
  "Malawi",
  "Taiwan",
  "Bolivia",
  "UAE*",
  "Singapore",
  "Bangladesh*",
  "Belgium",
  "Venezuela",
  "São Tomé and Príncipe",
  "Nigeria",
  "Jamaica",
  "Dominica",
  "Belarus*",
  "Antigua",
  "Ghana",
  "Benin",
  "El Salvador",
  "Reunion",
  "Uruguay",
  "Pakistan*",
  "Uganda",
  "Romania",
  "Madagascar",
  "Cyprus",
  "Comoros*",
  "Ukraine",
  "Djibouti",
  "Germany",
  "Czechia",
  "St. Vincent & Grenadines",
  "SE China*",
  "Italy",
  "Britain",
  "Kazakhstan*",
  "Mexico",
  "Georgia",
  "Bhutan",
  "Fiji",
  "Denmark",
  "Sweden",
  "Afghanistan*",
  "Rwanda",
  "Chile",
  "Albania",
  "Samoa",
  "New Zealand",
  "Ethiopia",
  "Barbados",
  "India",
  "France",
  "Sudan",
  "Hong Kong",
  "Togo",
  "Moldova",
  "Guinea Bissau",
  "Turkmenistan*",
  "American Samoa",
  "Cameroon",
  "Angola",
  "Brazil",
  "Sierra Leone",
  "Zimbabwe",
  "Dominican Republic",
  "Puerto Rico",
  "Namibia",
]