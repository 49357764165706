import React from 'react'
import './style.css'
import { RiLogoutBoxLine, RiMenu2Fill } from "react-icons/ri";
import { useLocation } from 'react-router-dom';

export default function Header({user}) {
  const location = useLocation()

  const OnLogout = () => {
    localStorage.removeItem("charter_admin")
    setTimeout(() => {
      window.location.reload()
    }, 500);
  }

  if(location.pathname === '/login'){
    return null
  }
  return (
    <div className='Header'>
      <RiLogoutBoxLine style={{cursor:"pointer"}} size={20} onClick={()=>OnLogout()}/>
      {/* <input placeholder='Search'/> */}
      <div className='FlexCon'/>
      <img src='https://ittacademy.net.au/wp-content/uploads/2022/12/bio-placeholder-img.jpg'/>
      <h3>{user?.role === 'super admin'? "Super Admin" : "Admin"}</h3>
    </div>
  )
}
